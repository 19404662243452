import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from '../loading.service';

@Component({
  selector: 'rct-full-page-spinner',
  templateUrl: './full-page-spinner.component.html',
  styleUrls: ['./full-page-spinner.component.scss'],
})
export class FullPageSpinnerComponent implements OnInit, OnDestroy {
  private loadingSub: Subscription;
  loading: boolean = false;

  constructor(private loadingService: LoadingService) {}

  ngOnInit(): void {
    this.loadingSub = this.loadingService.isLoading.subscribe((isLoading: boolean) => {
      this.loading = isLoading;
    })
  }

  ngOnDestroy(): void {
    this.loadingSub.unsubscribe();
  }
}
