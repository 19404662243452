import { AfterViewChecked, AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[rctPrimeOverlayId]'
})
export class PrimeOverlayIdDirective implements AfterViewChecked {

  @Input() rctPrimeOverlayId: string = '';

  constructor(private el: ElementRef) { }


  ngAfterViewChecked(): void {
    const overlay = this.el.nativeElement.querySelector('.p-component.p-overlaypanel');
    if (overlay) overlay.setAttribute('id', this.rctPrimeOverlayId);
  }
}
