import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rct-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss'],
})
export class CopyButtonComponent {
  @Input() title: string = 'Copy';
  @Input() copyValue: string;
  @Input() resetTime: number = 3000;

  @Input() successMessage: string = 'Copied!';
  @Input() errorMessage: string = 'Could not copy to clipboard';

  @Input() buttonStyle: { [klass: string]: any; } | null = {};
  @Input() iconStyle: { [klass: string]: any; } | null = {};
  @Input() successStyle: { [klass: string]: any; } | null = {};
  @Input() errorStyle: { [klass: string]: any; } | null = {};
  @Input() iconStyleClass: string = "";
  @Input() successMessageStyleClass: string = "";
  @Input() errorMessageStyleClass: string = "";

  used: boolean = false;
  error: boolean = false;

  @Output() copyEvent: EventEmitter<string> = new EventEmitter();

  copyToClipboard() {
    navigator.clipboard
      .writeText(this.copyValue)
      .then((res: any) => {
        this.copyEvent.emit(this.copyValue);
        this.used = true;
        setTimeout(() => {
          this.used = false;
        }, this.resetTime);
      })
      .catch((err: any) => {
        console.error(err);
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, this.resetTime);
      });
  }
}
