import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'rct-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Compliance Document Editor';

  constructor(private messagingService: MessageService) {}

  clearMessages(): void {
    this.messagingService.clear();
  }
}
