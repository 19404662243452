<form
  [formGroup]="ruleSetForm"
  class="p-fluid grid mt-3"
  id="ruleset-editor-form"
>
  <section class="col-12 grid grid-nogutter">
    <div class="col-12 md:col-8 field mb-0 mb-md-3">
      <label id="select-ruleset-label">Select a RuleSet:</label>
      <p-autoComplete
        formControlName="selectedRuleSet"
        rctPrimeDropdownId="ruleset-dropdown-options"
        rctPrimeDropdownButtonLabel="Expand rule set dropdown"
        rctPrimeDropdownButtonId="ruleset-dropdown-btn"
        *ngIf="ruleSetOptions"
        [dropdown]="true"
        [completeOnFocus]="true"
        [autoHighlight]="true"
        [suggestions]="ruleSetSuggestions"
        [field]="'name'"
        [dataKey]="'name'"
        [placeholder]="'Start typing to search'"
        [inputId]="'select-ruleset'"
        ariaLabelledBy="select-ruleset-label"
        (completeMethod)="getRuleSetSuggestions($event.query)"
        (onSelect)="
          unsavedChanges
            ? confirmSelectRuleSet($event)
            : handleSelectRuleSet($event)
        "
        class="{{ ruleSetError ? 'ng-invalid ng-dirty' : '' }}"
        styleClass="mb-2"
      >
        <ng-template let-ruleset pTemplate="item">
          <div id="ruleset-option-{{ ruleset._id }}">
            {{ ruleset.name }}
          </div>
        </ng-template>
      </p-autoComplete>
      <small *ngIf="ruleSetError" id="ruleset-error-message" class="p-error"
        >The {{ controls.selectedRuleSet.value.name }} RuleSet is not currently
        available.</small
      >
    </div>
    <p-divider class="col-2 hidden md:block" layout="vertical"
      ><span><strong>OR</strong></span></p-divider
    >
    <p-divider class="col-6 col-offset-3 sm:block md:hidden" align="center"
      ><span><strong>OR</strong></span></p-divider
    >
    <div
      class="
        col-12
        md:col-2
        flex
        justify-content-center
        align-items-center
        field
        mb-0
      "
    >
      <button
        pButton
        id="add-new-btn"
        type="button"
        label="Create New"
        title="Create new RuleSet"
        class="p-button-success p-button-sm main-btn"
        icon="pi pi-plus"
        (click)="unsavedChanges ? confirmStartNew() : handleStartNew()"
      ></button>
    </div>
  </section>
  <section class="field col-12 mt-3" *ngIf="currentRuleSetId">
    <rct-editor-toolbar
      toolbarId="ruleset-editor-toolbar"
      title="{{ currentRuleSetName || 'New' }} RuleSet"
      subtitle="{{ currentRuleSetId === '-1' ? '' : currentRuleSetId }}"
      editorMode="ruleSet"
      [disableSave]="!unsavedChanges || !controls.ruleSetContent.valid"
      [disableClone]="
        unsavedChanges ||
        !controls.ruleSetContent.valid ||
        validationStatus === 'failed'
      "
      [disableValidate]="unsavedChanges || !controls.ruleSetContent.valid"
      [disablePublish]="
        unsavedChanges ||
        !controls.ruleSetContent.valid ||
        validationStatus !== 'success'
      "
      [disableDiscard]="!unsavedChanges"
      [showValidationErrors]="validationStatus === 'failed'"
      [validationIsStale]="validationIsStale"
      [showAuditLog]="!isNew"
      (saveEvent)="handleSaveRuleSet()"
      (discardEvent)="confirmDiscardChanges()"
      (deleteEvent)="confirmDeleteRuleSet()"
      (cloneEvent)="handleCloneRuleSet()"
      (validateEvent)="handleValidateRuleSet()"
    >
    </rct-editor-toolbar>
    <div class="ruleset-content-container p-3">
      <p class="all-fields-required-notice">All fields are required.</p>
      <form
        formGroupName="ruleSetContent"
        class="grid align-items-start vertical-container mt-0"
        id="ruleset-content-form"
      >
        <span class="field col-12 lg:col-6 py-0">
          <span class="p-float-label">
            <input
              formControlName="name"
              id="ruleset-name-input"
              type="text"
              pInputText
              class="ruleset-form-input"
              maxLength="30"
              required
            />
            <label for="ruleset-name-input">Name</label>
          </span>
          <small
            *ngIf="controls.name.invalid && !isNew"
            class="p-error invalid-name-error-message"
            ><span *ngIf="controls.name.errors?.required"
              >Name is required.</span
            ><span
              *ngIf="
                !controls.name.errors?.required &&
                controls.name.errors?.inputEmpty
              "
              >Name cannot be empty.</span
            ><span
              *ngIf="
                !controls.name.errors?.required &&
                !controls.name.errors?.inputEmpty &&
                controls.name.errors?.maxLength
              "
              >Name must be 30 characters or less.</span
            ></small
          >
        </span>
        <span class="field col-12 md:col-6 lg:col-2 lg:col-offset-1 py-0">
          <span class="p-float-label">
            <p-dropdown
              formControlName="year"
              inputId="ruleset-year-input"
              rctPrimeDropdownId="ruleset-year-dropdown-options"
              rctPrimeDropdownButtonId="ruleset-year-dropdown-trigger"
              styleClass="ruleset-form-input"
              [autoDisplayFirst]="false"
              [options]="yearOptions"
              class="{{
                controls.year.invalid && !isNew ? 'ng-invalid ng-dirty' : ''
              }}"
              [required]="true"
            ></p-dropdown>
            <label for="ruleset-year-input">Year</label>
          </span>
          <small
            *ngIf="controls.year.invalid && !isNew"
            class="p-error invalid-year-error-message"
            ><span *ngIf="controls.year.errors?.required"
              >Year is required.</span
            ><span
              *ngIf="
                !controls.year.errors?.required &&
                controls.year.errors?.invalidYear
              "
              >Year must be between {{ validYearRange[0] }} and
              {{ validYearRange[1] }}.</span
            ></small
          >
        </span>
        <span class="field col-12 md:col-6 lg:col-2 lg:col-offset-1 py-0">
          <span class="p-float-label">
            <p-dropdown
              formControlName="type"
              inputId="ruleset-type-input"
              rctPrimeDropdownId="ruleset-type-dropdown-options"
              rctPrimeDropdownButtonId="ruleset-type-dropdown-trigger"
              styleClass="ruleset-form-input"
              [autoDisplayFirst]="false"
              [options]="ruleSetTypeOptions"
              class="{{
                controls.type.invalid && !isNew ? 'ng-invalid ng-dirty' : ''
              }}"
              [required]="true"
            ></p-dropdown>
            <label for="ruleset-type-input">Type</label>
          </span>
          <small
            *ngIf="controls.type.invalid && !isNew"
            class="p-error invalid-type-error-message"
            ><span *ngIf="controls.type.errors?.required"
              >Type is required.</span
            ><span
              *ngIf="
                !controls.type.errors?.required &&
                controls.type.errors?.invalidType
              "
              >This RuleSet currently has an invalid type: "{{
                controls.type.errors?.invalidType.value
              }}". Please select a valid type from the list.</span
            ></small
          >
        </span>
        <span class="field col-12 py-0">
          <span class="p-float-label">
            <input
              formControlName="description"
              id="ruleset-description-input"
              type="text"
              pInputText
              class="ruleset-form-input"
              required
            />
            <label for="ruleset-description-input">Description</label>
          </span>
          <small
            *ngIf="controls.description.invalid && !isNew"
            class="p-error invalid-description-error-message"
            ><span *ngIf="controls.description.errors?.required"
              >Description is required.</span
            ><span
              *ngIf="
                !controls.description.errors?.required &&
                controls.description.errors?.inputEmpty
              "
              >Description cannot be empty.
            </span>
          </small>
        </span>
        <p-pickList
          class="ruleset-picklist col-12"
          [source]="ruleOptions"
          [target]="selectedRules"
          sourceHeader="Available Rules"
          targetHeader="Included In RuleSet"
          [dragdrop]="true"
          [responsive]="true"
          filterBy="name"
          rctPrimePicklistIds
          sourceFilterId="available-rules-filter-input"
          targetFilterId="included-rules-filter-input"
          sourceListboxId="available-rules-list"
          targetListboxId="included-rules-list"
          moveToTargetButtonId="add-rule-btn"
          moveToSourceButtonId="remove-rule-btn"
          moveAllToTargetButtonId="add-all-rules-btn"
          moveAllToSourceButtonId="remove-all-rules-btn"
          sourceFilterPlaceholder="Search by name"
          targetFilterPlaceholder="Search by name"
          [showSourceControls]="false"
          [showTargetControls]="false"
          (onMoveToTarget)="handleAddRule($event.items)"
          (onMoveToSource)="handleRemoveRule($event.items)"
          ><ng-template let-rule pTemplate="item">
            <div
              [class]="ruleError === rule._id ? 'rule-error' : ''"
              class="
                picklist-item
                flex
                justify-content-between
                align-items-center
              "
              id="rule-option-{{rule._id}}"
            >
              <p class="m-0 list-item-name">{{ rule.name }}</p>
              <p *ngIf="ruleError === rule._id" class="p-error m-0">
                This rule could not be retrieved.
              </p>
              <button
                pButton
                pRipple
                type="button"
                id="view-rule-btn-{{rule._id}}"
                [class]="
                  ruleError === rule._id ? 'p-button-danger' : 'p-button-info'
                "
                class="p-button-rounded p-button-text mr-2"
                icon="pi pi-eye"
                (click)="handleViewRule($event, rule._id)"
              ></button>
            </div>
          </ng-template>
          <ng-template pTemplate="emptymessagesource">
            <div
              class="
                no-rules-available-message
                flex
                align-items-center
                justify-content-center
              "
              [ngSwitch]="rulesLoading"
            >
              <div
                *ngSwitchCase="false"
                class="
                  flex flex-column
                  align-items-center
                  justify-content-center
                "
              >
                <i class="pi pi-folder-open"></i>
                <p>No rules available</p>
              </div>
              <div
                *ngSwitchCase="true"
                class="
                  flex flex-column
                  align-items-center
                  justify-content-center
                "
              >
                <i class="pi pi-spinner pi-spin"></i>
                <p>Loading rules...</p>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="emptymessagetarget">
            <div
              class="
                no-rules-in-ruleset-message
                flex flex-column
                align-items-center
                justify-content-center
              "
              [ngSwitch]="rulesLoading"
            >
              <div
                *ngSwitchCase="false"
                class="
                  flex flex-column
                  align-items-center
                  justify-content-center
                "
              >
                <i class="pi pi-folder-open block"></i>
                <p>This RuleSet contains no rules</p>
              </div>
              <div
                *ngSwitchCase="true"
                class="
                  flex flex-column
                  align-items-center
                  justify-content-center
                "
              >
                <i class="pi pi-spinner pi-spin"></i>
                <p>Loading rules...</p>
              </div>
            </div>
          </ng-template>
        </p-pickList>
      </form>
    </div>
  </section>
</form>

<rct-validation-spinner *ngIf="validating"></rct-validation-spinner>
