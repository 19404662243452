import { Injectable } from '@angular/core';

type TValidOption = string | { [key: string]: any };

@Injectable({
  providedIn: 'root',
})
export class AutocompleteService {
  private isEmptyRegex: RegExp = /^\s*$/;

  /**
   * Filters a list of options using a provided query string or function and returns the new list
   * @param options an array of possible options - either all strings or all objects
   * @param {string | Function} query either a string used to search within an option or option field, or a filter function
   * @param {string} queryField if options is an array of objects, which field to search on
   */
  // Overload 1 - can take an array of strings and a query string, returning an array of strings
  getSuggestions(options: string[], query: string): string[]; 
  // Overload 2 - can take an array of objects, a query string, and a field to query on, returning an array of objects
  getSuggestions(
    options: { [key: string]: any }[],
    query: string,
    queryField: string
  ): { [key: string]: any }[]; 
  // Overload 3 - can take an array of strings or objects and a query function, returning an array of strings or objects
  getSuggestions(
    options: TValidOption[],
    query: (
      option: any,
      index?: number,
      optionsArray?: TValidOption[]
    ) => boolean
  ): TValidOption[]; 
  getSuggestions(
    options: TValidOption[],
    query: any,
    queryField?: string
  ): any[] {
    let suggestions: TValidOption[] = [];
    if (typeof query === 'string') {
      // Overloads 1 and 2
      if (this.isEmptyRegex.test(query)) {
        // if query string is empty, return all options
        suggestions = [...options];
      } else {
        query = query.toLowerCase();
        if (typeof options[0] === 'string') {
          // if options are strings, search options directly using query string
          suggestions = options.filter((o: TValidOption) => {
            return o.toLowerCase().includes(query);
          });
        } else {
          // if options are objects, search specified query field values using query string
          suggestions = options.filter((o: TValidOption) => {
            const searchValue = (o as any)[queryField as string];
            if (typeof searchValue !== 'string') {
              console.error(
                `You are attempting to query on a nonexistent or non-string field.`
              );
              return false;
            }
            return searchValue.toLowerCase().includes(query);
          });
        }
      }
    } else if (typeof query === 'function') { 
      // Overload 3 - filter using passed-in query function
      suggestions = options.filter(query);
    }
    return suggestions;
  }
}
