import { Injectable } from '@angular/core';
import { FormatterService } from '../formatter/formatter.service';
import * as StateModel from '../models/state.model';

interface IValidationErrorMessage {
  description: string;
  solution: string;
}

@Injectable({
  providedIn: 'root',
})
export class ValidationErrorMessageService {
  constructor(private formatter: FormatterService) {}

  generateErrorMessage(
    error: StateModel.IValidationError
  ): IValidationErrorMessage {
    const message = {
      description: error.description,
      solution: 'No suggested solution.',
    };

    switch (true) {
      case /^1001/.test(error.code): // any year errors
        message.solution = `Select a valid year from the dropdown and save.`;
        break;
      case /^1002/.test(error.code): // any name errors
        message.solution = `Enter a RuleSet name that is 30 characters or less and save.`;
        break;
      case /^1003/.test(error.code): // any type errors
        message.description = this.formatter.formatRuleSet(error.description);
        message.solution = `Select a valid type from the dropdown and save.`;
        break;
      case /^1004/.test(error.code): // any description errors
        message.solution = `Enter a description for the RuleSet and save.`;
        break;
      case /100701/.test(error.code): // rules ref is not defined
        message.solution = `Make an update to any RuleSet field and save to update property name.`;
        break;
      case /100702|100703/.test(error.code): // rules ref empty/at least one rule must be referenced
        message.solution = `Ensure at least one rule is added to the RuleSet.`;
        break;
      case /200201/.test(error.code): // rule not found
        message.solution = `Ensure rule "${error.name}" exists or remove it from the RuleSet.`;
        break;
      case /200301/.test(error.code): // field not found
        message.description = `Field "${error.name}" was not found.`;
        message.solution = `Ensure field "${error.name}" exists or remove it from all rules.`;
        break;
      case /200401/.test(error.code): // menu not found
        message.description = `Menu "${error.name}" was not found.`;
        message.solution = `Ensure menu "${error.name}" exists or remove it from all rules.`;
        break;
    }

    return message;
  }
}
