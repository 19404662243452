import {
  AfterViewChecked,
  Directive,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';

@Directive({
  selector: '[rctPrimeDropdownId]',
})
export class PrimeDropdownIdDirective implements OnInit, AfterViewChecked {
  private componentType: 'p-dropdown' | 'p-autocomplete';
  @Input() rctPrimeDropdownId: string = '';

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.componentType = this.el.nativeElement.localName; // local name is either 'p-autocomplete' or 'p-dropdown'
  }

  ngAfterViewChecked(): void {
    const listbox = this.el.nativeElement.querySelector(
      `ul.${this.componentType}-items`
    );
    if (!listbox || listbox.id === this.rctPrimeDropdownId) return;
    listbox.setAttribute('id', this.rctPrimeDropdownId);
  }
}
