<div class="audit-log-wrapper flex flex-column align-items-center">
  <div class="audit-log-status-bar flex justify-content-evenly mb-5 mt-3">
    <div
      class="
        audit-log-status
        flex flex-column
        justify-content-center
        align-items-center
      "
    >
      <h3 id="saved-status-title">Saved</h3>
      <button
        pButton
        *ngIf="saved"
        icon="pi pi-check"
        class="p-button-rounded p-button-success inert-btn saved-status-icon"
        pTooltip="RuleSet has no unsaved changes."
      ></button>
      <button
        pButton
        *ngIf="!saved"
        icon="pi pi-times"
        class="p-button-rounded p-button-danger inert-btn saved-status-icon"
        pTooltip="RuleSet has unsaved changes."
      ></button>
    </div>
    <p-divider layout="vertical"></p-divider>
    <div
      class="
        audit-log-status
        flex flex-column
        justify-content-center
        align-items-center
      "
      [ngSwitch]="validated"
    >
      <h3 id="validated-status-title">Validated</h3>
      <button
        pButton
        *ngSwitchCase="'yes'"
        icon="pi pi-check"
        class="p-button-rounded p-button-success inert-btn validated-status-icon"
        pTooltip="RuleSet has been successfully validated."
      ></button>
      <button
        pButton
        *ngSwitchCase="'missingChanges'"
        icon="pi pi-clock"
        class="p-button-rounded p-button-warning inert-btn validated-status-icon"
        pTooltip="Changes have been made since last successful validation."
      ></button>
      <button
        pButton
        *ngSwitchCase="'no'"
        icon="pi pi-times"
        class="p-button-rounded p-button-danger inert-btn validated-status-icon"
        pTooltip="RuleSet has not been successfully validated."
      ></button>
    </div>
    <p-divider layout="vertical"></p-divider>
    <div
      class="
        audit-log-status
        flex flex-column
        justify-content-center
        align-items-center
      "
      [ngSwitch]="published"
    >
      <h3 id="published-status-title">Published</h3>
      <button
        pButton
        *ngSwitchCase="'upToDate'"
        icon="pi pi-check"
        class="p-button-rounded p-button-success inert-btn published-status-icon"
        pTooltip="Most recent version has been published."
      ></button>
      <button
        pButton
        *ngSwitchCase="'missingChanges'"
        icon="pi pi-clock"
        class="p-button-rounded p-button-warning inert-btn published-status-icon"
        pTooltip="Changes have been made since last published."
      ></button>
      <button
        pButton
        *ngSwitchCase="'never'"
        icon="pi pi-times"
        class="p-button-rounded p-button-danger inert-btn published-status-icon"
        pTooltip="RuleSet has never been published."
      ></button>
    </div>
  </div>
  <p-panel  class="panel" styleClass="audit-log-panel mb-5">
    <ng-template pTemplate="header">
      <h3 id="created-at-panel-title" class="p-panel-title m-0">
        Created:
        <span class="audit-log-panel-header-date">{{ createdAt }}</span>
      </h3>
    </ng-template>
    <p><strong>Author Key:</strong> {{ originalAuthor.key }}</p>
    <p>
      <strong>Author Facility Key:</strong>
      {{ originalAuthor.facility }}
    </p>
  </p-panel>
  <p-panel class="panel" styleClass="audit-log-panel">
    <ng-template pTemplate="header">
      <h3 id="last-updated-at-panel-title" class="p-panel-title m-0">
        Last Updated:
        <span class="audit-log-panel-header-date">{{ lastUpdatedAt }}</span>
      </h3>
    </ng-template>
    <p>
      <strong>Author Key:</strong>
      {{ lastUpdateAuthor.key }}
    </p>
    <p>
      <strong>Author Facility Key:</strong>
      {{ lastUpdateAuthor.facility }}
    </p>
  </p-panel>
</div>
