import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[rctPrimeDropdownButtonId]',
})
export class PrimeDropdownButtonIdDirective implements AfterViewInit {
  @Input() rctPrimeDropdownButtonId: string = '';

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    let button = this.el.nativeElement.querySelector('button');
    if (!button) button = this.el.nativeElement.querySelector('div.p-dropdown-trigger');
    button.setAttribute('id', this.rctPrimeDropdownButtonId);
  }
}
