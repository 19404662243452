import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { AuditLogComponent } from '../audit-log/audit-log.component';

@Component({
  selector: 'rct-editor-toolbar',
  templateUrl: './editor-toolbar.component.html',
  styleUrls: ['./editor-toolbar.component.scss'],
})
export class EditorToolbarComponent implements OnChanges {
  @Input() toolbarId: string = '';
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() addCopyButton: 'title' | 'subtitle' | 'both' = 'subtitle';

  @Input() editorMode: 'document' | 'ruleSet';

  @Input() disableSave: boolean = true;
  @Input() disableDiscard: boolean = true;
  @Input() disableClone: boolean = false;
  @Input() disableValidate: boolean = false;
  @Input() disablePublish: boolean = false;

  @Input() showValidationErrors: boolean = false;
  @Input() validationIsStale: boolean = false;

  @Input() showAuditLog: boolean = false;

  @Output() saveEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() discardEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleteEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() cloneEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() validateEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() publishEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() validationOverlayEvent: EventEmitter<'show'|'hide'> = new EventEmitter<'show'|'hide'>();

  validateMenuItems: MenuItem[] = [
    {
      id: 'publish-btn',
      label: 'Publish',
      icon: 'pi pi-globe',
      command: () => {
        this.handlePublishEvent();
      },
    },
  ];

  deleteMenuItems: MenuItem[] = [
    {
      id: 'discard-btn',
      label: 'Discard Changes',
      icon: 'pi pi-times',
      command: () => {
        this.handleDiscardEvent();
      },
    },
  ];

  collapsed: boolean = false;

  constructor(private dialogService: DialogService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disableDiscard) {
      this.setDiscardButtonDisabled(changes.disableDiscard.currentValue);
    }
    if (changes.disablePublish) {
      this.setPublishButtonDisabled(changes.disablePublish.currentValue);
    }
  }

  shouldAddCopyButtonToSubtitle(): boolean {
    return this.addCopyButton === 'subtitle' || this.addCopyButton === 'both';
  }

  shouldAddCopyButtonToTitle(): boolean {
    return this.addCopyButton === 'title' || this.addCopyButton === 'both';
  }

  handleSaveEvent(): void {
    this.saveEvent.emit('');
  }

  handleDiscardEvent(): void {
    this.discardEvent.emit('');
  }

  handleDeleteEvent(): void {
    this.deleteEvent.emit('');
  }

  handleCloneEvent(): void {
    this.cloneEvent.emit('');
  }

  handleValidateEvent(): void {
    this.validateEvent.emit('');
  }

  handlePublishEvent(): void {
    this.publishEvent.emit('');
  }

  setDiscardButtonDisabled(isDisabled: boolean): void {
    this.deleteMenuItems[0].disabled = isDisabled;
  }

  setPublishButtonDisabled(isDisabled: boolean): void {
    this.validateMenuItems[0].disabled = isDisabled;
  }

  handleAuditLogEvent(): void {
    const ref = this.dialogService.open(AuditLogComponent, {
      header: `Audit Log`,
      width: '500px',
      styleClass: 'audit-log-dialog',
      contentStyle: { width: '100%', height: '100%' },
      dismissableMask: true
    });
  }

  handleCollapse(): void {
    setTimeout(() => (this.collapsed = true)); //ensure update happens after Angular change detection cycle
  }

  handleExpand(): void {
    setTimeout(() => (this.collapsed = false)); //ensure update happens after Angular change detection cycle
  }
}
