import {
  AfterViewChecked,
  AfterViewInit,
  Directive,
  ElementRef,
} from '@angular/core';

@Directive({
  selector: '[rctHideCollapsedJsonContent]',
})
export class HideCollapsedJsonContentDirective
  implements AfterViewInit, AfterViewChecked
{
  private collapsedObjectRegex: RegExp = /Object(?=\s\{)/;
  private collapsedArrayRegex: RegExp = /Array\[(\d+)\]/;

  objectSegments: HTMLCollection;
  arraySegments: HTMLCollection;

  objectReplacementText: string = 'Object {...}';

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.objectSegments = this.el.nativeElement.getElementsByClassName(
      'segment segment-type-object'
    );
    this.arraySegments = this.el.nativeElement.getElementsByClassName(
      'segment segment-type-array'
    );
  }

  ngAfterViewChecked(): void {
    if (this.arraySegments.length) {
      this.replaceAllCollapsedSegmentText(
        this.arraySegments,
        this.collapsedArrayRegex
      );
    }
    if (this.objectSegments.length) {
      this.replaceAllCollapsedSegmentText(
        this.objectSegments,
        this.collapsedObjectRegex,
        this.objectReplacementText
      );
    }
  }

  /**
   * Replace text of all collapsed object/array segments with provided text or the result of the matcher
   * @param segments an HTML collection of NGX Json Viewer segment elements (HTML representations of either JSON arrays or JSON objects)
   * @param matcher a regex used to find collapsed array/object segments based on a segment's innerText
   * @param replacement optional - if provided it will be used as replacement text, if not provided the result of the matcher will be used instead
   */
  replaceAllCollapsedSegmentText(
    segments: HTMLCollection,
    matcher: RegExp,
    replacement?: string
  ): void {
    for (let i = 0; i < segments.length; i++) {
      const segment = segments[i];
      if (!segment) continue;
      const segmentValue = segment.querySelector(
        'span.segment-value'
      ) as HTMLElement;
      const text = segmentValue?.innerText;
      if (!text) continue;
      const match = text.match(matcher);
      if (!match) continue;
      const replacer = replacement === undefined ? match[0] : replacement;
      segmentValue.innerText = replacer;
    }
  }
}
