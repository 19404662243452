<p-toolbar
  id="{{ toolbarId }}"
  styleClass="toolbar py-2"
  rctToolbarBreakpoints
  (expandEvent)="handleExpand()"
  (collapseEvent)="handleCollapse()"
>
  <div
    class="
      header-text
      p-toolbar-group-left
      flex flex-column
      justify-content-center
      {{ collapsed ? 'collapsed' : 'expanded' }}
    "
  >
    <h2
      #editorTitle
      class="editor-title mt-0 mb-1 py-0"
      style="width: 'fit-content'; color: white"
    >
      <span>{{ title }}</span>
      <rct-copy-button
        *ngIf="shouldAddCopyButtonToTitle()"
        title="Copy editor title to clipboard"
        [copyValue]="title"
        [resetTime]="5000"
        [buttonStyle]="{
          color: 'white',
          'padding-left': 0,
          'padding-top': 0,
          'padding-bottom': 0
        }"
      ></rct-copy-button>
    </h2>
    <h3
      *ngIf="subtitle"
      class="white-space-nowrap editor-subtitle col-12 md:col-6 mt-1 mb-0 py-0"
    >
      <span class="light-text">{{ subtitle }}</span>
      <rct-copy-button
        *ngIf="shouldAddCopyButtonToSubtitle()"
        title="Copy editor subtitle to clipboard"
        [copyValue]="subtitle"
        [resetTime]="5000"
        [buttonStyle]="{
          color: '#969fa7',
          'margin-left': '0.3em',
          'padding-top': 0,
          'padding-bottom': 0
        }"
      ></rct-copy-button>
    </h3>
  </div>
  <div *ngIf="showValidationErrors">
    <rct-validation-error-display
      [stale]="validationIsStale"
    ></rct-validation-error-display>
  </div>
  <div
    #buttonContainer
    class="
      flex
      justify-content-center
      pr-3
      mt-2
      p-toolbar-group-right
      toolbar-button-container
      lg:justify-content-end lg:mt-0
      {{ collapsed ? 'collapsed' : 'expanded' }}
    "
  >
    <button
      pButton
      id="save-btn"
      type="button"
      label="Save"
      title="Save"
      class="p-button-info p-button-sm main-btn toolbar-btn"
      icon="pi pi-save"
      (click)="handleSaveEvent()"
      [disabled]="disableSave"
    ></button>
    <button
      *ngIf="editorMode === 'ruleSet'"
      pButton
      id="clone-btn"
      type="button"
      label="Clone"
      title="Clone"
      class="p-button-info p-button-sm main-btn toolbar-btn"
      icon="pi pi-copy"
      (click)="handleCloneEvent()"
      [disabled]="disableClone"
    ></button>
    <p-splitButton
      *ngIf="editorMode === 'ruleSet'"
      label="Validate"
      icon="pi pi-check-circle"
      (onClick)="handleValidateEvent()"
      [model]="validateMenuItems"
      styleClass="p-button-info"
      rctPrimeSplitButtonIds
      defaultButtonId="validate-btn"
      menuButtonId="validate-menu-btn"
      menuId="validate-menu"
      rctAddSplitButtonClasses
      defaultButtonStyleClass="p-button-sm main-btn toolbar-btn"
      menuButtonStyleClass="p-button-sm main-btn toolbar-btn m-0"
    ></p-splitButton>
    <p-splitButton
      label="Delete"
      icon="pi pi-trash"
      (onClick)="handleDeleteEvent()"
      [model]="deleteMenuItems"
      styleClass="p-button-danger"
      rctPrimeSplitButtonIds
      defaultButtonId="delete-btn"
      menuButtonId="delete-menu-btn"
      menuId="delete-menu"
      rctAddSplitButtonClasses
      defaultButtonStyleClass="p-button-sm main-btn toolbar-btn"
      menuButtonStyleClass="p-button-sm main-btn toolbar-btn m-0"
    ></p-splitButton>
    <button
      *ngIf="editorMode === 'ruleSet' && showAuditLog"
      pButton
      id="audit-log-btn"
      type="button"
      title="View Audit Log"
      class="p-button-info p-button-sm main-btn toolbar-btn"
      icon="pi pi-book"
      (click)="handleAuditLogEvent()"
    ></button>
  </div>
</p-toolbar>
