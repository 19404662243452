<form [formGroup]="form" class="p-fluid grid mt-3" id="document-editor-form">
  <section class="field col-12 lg:col-4">
    <label id="select-doc-type-label">Select a document type:</label>
    <p-autoComplete
      formControlName="docType"
      rctPrimeDropdownId="doc-type-dropdown-options"
      rctPrimeDropdownButtonLabel="Expand document type dropdown"
      rctPrimeDropdownButtonId="doc-type-dropdown-btn"
      *ngIf="docTypeOptions"
      [dropdown]="true"
      [completeOnFocus]="true"
      [autoHighlight]="true"
      [suggestions]="docTypeSuggestions"
      [field]="'displayName'"
      [dataKey]="'name'"
      [placeholder]="'Start typing to search'"
      [inputId]="'select-doc-type'"
      ariaLabelledBy="select-doc-type-label"
      (completeMethod)="getDocTypeSuggestions($event.query)"
      (onSelect)="
        unsavedChanges
          ? confirmSelectDocType($event)
          : handleSelectDocType($event)
      "
      class="{{ docTypeError ? 'ng-invalid ng-dirty' : '' }}"
      styleClass="mb-2"
    >
      <ng-template let-option pTemplate="item">
        <div id="doc-type-option-{{ option.name }}">
          <span>
            <strong>
              {{ option.displayName }}
            </strong>
          </span>
          <span>
            <em> - {{ option.count }} documents</em></span
          >
        </div>
      </ng-template>
    </p-autoComplete>
    <small *ngIf="docTypeError" id="doc-type-error-message" class="p-error"
      >The {{ selectedDocTypeOption.name }} collection is not currently
      available.</small
    >
  </section>
  <section
    class="col-12 lg:col-7 lg:col-offset-1 grid grid-nogutter"
    *ngIf="selectedDocTypeOption && !docTypeError"
  >
    <div class="col-12 md:col-7 field mb-0 md:mb-3">
      <label id="select-doc-label">{{ makeDocTypeLabel() }}</label>
      <p-autoComplete
        formControlName="selectedDocOption"
        rctPrimeDropdownId="document-dropdown-options"
        rctPrimeDropdownButtonLabel="Expand document dropdown"
        rctPrimeDropdownButtonId="document-dropdown-btn"
        *ngIf="docOptions"
        [dropdown]="true"
        [completeOnFocus]="true"
        [autoHighlight]="true"
        [suggestions]="docSuggestions"
        [field]="'name'"
        [dataKey]="'_id'"
        [placeholder]="'Start typing to search'"
        [inputId]="'select-doc'"
        ariaLabelledBy="select-doc-label"
        (completeMethod)="getDocSuggestions($event.query)"
        (onSelect)="
          unsavedChanges ? confirmSelectDoc($event) : handleSelectDoc($event)
        "
        class="{{ docError ? 'ng-invalid ng-dirty' : '' }}"
        styleClass="mb-2"
      >
        <ng-template let-doc pTemplate="item">
          <div id="doc-option-{{ doc._id }}">
            {{ doc.name }}
          </div>
        </ng-template>
      </p-autoComplete>
      <small *ngIf="docError" id="doc-error-message" class="p-error"
        >There was a problem loading this document.</small
      >
    </div>
    <p-divider class="col-2 hidden md:block" layout="vertical"
      ><span><strong>OR</strong></span></p-divider
    >
    <p-divider class="col-6 col-offset-3 sm:block md:hidden" align="center"
      ><span><strong>OR</strong></span></p-divider
    >
    <div
      class="
        col-12
        md:col-3
        flex
        justify-content-center
        align-items-center
        field
        mb-0
      "
    >
      <button
        pButton
        id="add-new-btn"
        type="button"
        label="Create New"
        title="Create new document"
        class="p-button-success p-button-sm main-btn"
        icon="pi pi-plus"
        (click)="unsavedChanges ? confirmAddNew($event) : handleAddNew($event)"
      ></button>
    </div>
  </section>
  <section
    class="col-12 mb-0 mt-3"
    class="{{ jsonError ? 'json-error' : '' }}"
    class="{{ currentDocId && !docTypeError && !docError ? '' : 'hidden' }}"
  >
    <rct-editor-toolbar
      toolbarId="document-editor-toolbar"
      [title]="makeEditorTitle()"
      [subtitle]="currentDocId === '-1' ? '' : currentDocId"
      editorMode="document"
      [disableSave]="!unsavedChanges"
      [disableDiscard]="!unsavedChanges"
      (saveEvent)="handleSaveDoc()"
      (discardEvent)="confirmDiscardChanges()"
      (deleteEvent)="confirmDeleteDoc()"
    ></rct-editor-toolbar>
    <div>
      <json-editor
        id="json-editor"
        [options]="jsonEditorConfig"
        formControlName="docContent"
        [debug]="false"
        style="min-height: 43vh; max-height: 43vh; overflow: scroll"
      ></json-editor>
      <small *ngIf="jsonError" id="json-error-message" class="p-error">{{
        jsonEditor.getText()
          ? "Please fix the JSON errors indicated above before proceeding"
          : "JSON cannot be empty"
      }}</small>
    </div>
  </section>
</form>
