import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FormatterService {
  /**
   * capitalizes the first character of a string
   * @param {string} str
   * @returns {string}
   */
  capitalize = (str?: string): string => {
    if (!str || typeof str !== 'string') return '';
    return str[0].toUpperCase() + str.slice(1);
  };

  /**
   * capitalizes each word in a string, as delineated by single spaces
   * @param {string} str
   * @returns {string}
   */
  capitalizeAll = (str?: string) => {
    if (!str || typeof str !== 'string') return '';
    const words = str.split(' ');
    return words.map((w) => this.capitalize(w)).join(' ');
  };

  /**
   * replaces all interior "_", including groups of "_", with single spaces and removes all leading/trailing "_"
   * @param {string} str
   * @returns {string}
   * e.g. passing "collin_snyder", "_collin_snyder_", or "collin_____snyder" all return "collin snyder"
   */
  snakeCaseToWords = (str?: string): string => {
    if (!str || typeof str !== 'string') return '';
    return str
      .replace(/^_|_$/g, '') // remove leading/trailing _
      .replace(/(_(?=_)|(?<=_)_)+/g, ' ') // replace groups of multiple _ with single spaces
      .replace(/_/g, ' '); // replace all remaining _ with single spaces
  };

  /**
   * converts a snake_case_string into a CamelCaseString
   * @param {string} str 
   * @returns {string}
   */
  snakeCaseToCamelCase = (str?: string): string => {
    return this.capitalizeAll(this.snakeCaseToWords(str)).replace(/\s*/g, '');
  };

  /**
   * converts any string version of RuleSet (rule set, ruleset, Rule Set, etc) into proper formatting - RuleSet
   * @param str 
   */
  formatRuleSet = (str?: string): string => {
    if (!str) return '';
    return str.replace(/rule set|ruleset/ig, 'RuleSet');
  }
}
