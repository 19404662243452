import { Injectable } from '@angular/core';
import { combineLatest, Observable, pipe } from 'rxjs';
import { ApiService } from '../core/api/api.service';
import { DocumentEditorStateService } from '../core/state/document-editor-state.service';
import * as StateModel from '../core/models/state.model';
import * as DocumentEditorModel from '../components/document-editor/document-editor.model';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { FormatterService } from '../core/formatter/formatter.service';
import { DocParserService } from '../core/doc-parser/doc-parser.service';
import { TValidCollectionName } from '../core/models/api.model';
import { UtilsService } from '../core/utils/utils.service';
import { LoadingService } from '../loading/loading.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentEditorFacade {
  constructor(
    private loadingService: LoadingService,
    private api: ApiService,
    private state: DocumentEditorStateService,
    private formatter: FormatterService,
    private docParser: DocParserService,
    private utils: UtilsService
  ) {}

  // state getters

  getDocTypeOptions(): Observable<DocumentEditorModel.IDocTypeOption[]> {
    return this.state.docTypeOptions;
  }

  getDocOptions(): Observable<DocumentEditorModel.IDocOption[]> {
    return this.state.docOptions.pipe(
      map((docs: DocumentEditorModel.IDocOption[]) =>
        docs.map(({ _id, name }) => {
          return { _id, name };
        })
      )
    );
  }

  getSelectedDocTypeOption(): Observable<DocumentEditorModel.IDocTypeOption | null> {
    return this.state.docType;
  }

  getSelectedDocOption(): Observable<DocumentEditorModel.IDocOption | null> {
    return combineLatest([
      this.state.currentDocId,
      this.state.currentDocContent,
    ]).pipe(
      map(([_id, content]: any) => {
        if (!_id || !content) return null;
        return { _id, name: content.name };
      })
    );
  }

  getSelectedDocContent(): Observable<DocumentEditorModel.IDocContent | null> {
    return this.state.currentDocContent;
  }

  getCurrentDocId(): Observable<string> {
    return this.state.currentDocId;
  }

  getCurrentDocName(): Observable<string> {
    return this.state.currentDocContent.pipe(
      map((content: StateModel.TCurrentDocContentState) => content?.name || '')
    );
  }

  getCurrentDocMetadata(): Observable<StateModel.TCurrentDocMetadataState> {
    return this.state.currentDocMetadata;
  }

  getFullDoc(): Observable<any> {
    return combineLatest([
      this.state.currentDocId,
      this.state.currentDocContent,
      this.state.currentDocMetadata,
    ]).pipe(
      map(([_id, content, metadata]: any) =>
        this.docParser.compileFullDoc({ _id, content, metadata })
      )
    );
  }

  getIsNewDoc(): Observable<boolean> {
    return this.state.isNewDoc;
  }

  getTemplateName(): Observable<string> {
    return this.state.templateName;
  }

  getHasUnsavedChanges(): Observable<boolean> {
    return this.state.hasUnsavedChanges;
  }

  getTemplateOptions(): Observable<any[]> {
    return this.state.templateOptions;
  }

  // Data-loading API calls

  /**
   * Fetch all available collections from API and load them into state
   * @returns {Observable} Observable resolving to a list of all docType options
   */
  loadDocTypes(): Observable<StateModel.IDocTypeOption[]> {
    return this.api.getCollectionList().pipe(
      map((options: StateModel.IDocTypeOption[]) =>
        options.filter(this._filterDocTypes).map((o) => {
          // add formatted display name
          o.displayName = this.formatter.capitalizeAll(
            this.formatter.snakeCaseToWords(o.name)
          );
          return o;
        })
      ),
      tap((options: StateModel.IDocTypeOption[]) => {
        this.state.setDocTypeOptions(options);
      }),
      catchError((err) => {
        console.error(err);
        throw err;
      })
    );
  }

  /**
   * Fetch all template (module) options from API and load them into state
   * @returns {Observable} Observable resolving to a list of all template options
   */
  loadTemplateOptions(): Observable<StateModel.IDocOption[]> {
    return this.api.getDocumentList('module').pipe(
      tap((templates: StateModel.IDocOption[]) => {
        this.state.setTemplateOptions(templates);
      }),
      catchError((err) => {
        console.error(err);
        throw err;
      })
    );
  }

  /**
   * Fetch all documents for the given collection and load into state
   * @param collection string - a valid collection (docType) name
   * @returns {Observable}
   */
  loadDocOptions(collection: TValidCollectionName): Observable<any> {
    return this.api.getDocumentList(collection).pipe(
      map((list: StateModel.IFullDoc[]) => {
        return list.map((doc) => {
          doc.name = this.utils.coerceToString(doc.name);
          return doc;
        });
      }),
      tap((options: StateModel.IFullDoc[]) => {
        this.state.setDocOptions(options);
      }),
      catchError((err: any) => {
        console.error(err);
        throw err;
      })
    );
  }

  /**
   * Fetch a document by ID and load into state as either a new doc or a template
   * @param collection string - a valid collection (docType) name
   * @param id string - ID of a document
   * @param loadAs either 'document' or 'template' - dictates what parsing/state-setting logic to perform
   * @returns {Observable}
   */
  loadDocument(
    collection: TValidCollectionName,
    id: string,
    loadAs: 'document' | 'template' = 'document'
  ): Observable<StateModel.IFullDoc> {
    const resolutionPipe =
      loadAs === 'document'
        ? this._resolveDocumentPipe
        : this._resolveTemplatePipe;

    return this.api.getDocument(collection, id).pipe(
      this._clearCurrentDocPipe(),
      map((doc: StateModel.IFullDoc) => {
        doc.name = this.utils.coerceToString(doc.name);
        return doc;
      }),
      resolutionPipe(),
      catchError((err: any) => {
        console.error(err);
        throw err;
      })
    );
  }

  // CRUD API calls requiring loading service

  /**
   * Fetch one template (module) document from API and load into state
   * @param id string ID of template (module) document
   * @returns {Observable} resolving to
   */
  setCurrentTemplate(id: string): Observable<any[]> {
    this.loadingService.startLoading();
    return this.loadDocument('module', id, 'template').pipe(
      this._stopLoadingPipe(),
      catchError((err) => {
        this.loadingService.stopLoading();
        this.startNewDoc();
        console.error(err);
        throw err;
      })
    );
  }

  /**
   * Fetch all documents for given docType and load into state
   * @param docType a DocTypeOption object
   * @returns {Observable}
   */
  setDocType(docType: DocumentEditorModel.IDocTypeOption): Observable<any> {
    this.loadingService.startLoading();
    return this.loadDocOptions(docType.name).pipe(
      tap(() => {
        this.state.setDocType(docType);
      }),
      this._clearCurrentDocPipe(),
      this._stopLoadingPipe(),
      catchError((err: any) => {
        this.state.setDocType(docType);
        this.loadingService.stopLoading();
        throw err;
      })
    );
  }

  /**
   * Fetch single document from API and load into state
   * @param docId string ID of document
   * @returns {Observable}
   */
  setCurrentDoc(docId: string): Observable<any> {
    this.loadingService.startLoading();
    return this.loadDocument(
      this.state.docType.getValue()?.name as TValidCollectionName,
      docId
    ).pipe(
      this._stopLoadingPipe(),
      catchError((err) => {
        this.loadingService.stopLoading();
        console.error(err);
        throw err;
      })
    );
  }

  saveNewDoc(doc: StateModel.IDocContent): Observable<any> {
    this.loadingService.startLoading();

    const collection = this.state.docType.getValue()
      ?.name as TValidCollectionName;

    return this.api.createDocument(collection, doc).pipe(
      concatMap(({ doc_id }) => this.loadDocument(collection, doc_id)),
      concatMap((doc) => this.loadDocOptions(collection)),
      this._stopLoadingPipe(),
      catchError((err: any) => {
        this.loadingService.stopLoading();
        throw err;
      })
    );
  }

  updateCurrentDoc(doc: DocumentEditorModel.IDocContent): Observable<any> {
    this.loadingService.startLoading();

    const collection = this.state.docType.getValue()
      ?.name as TValidCollectionName;
    const document = this.docParser.compileFullDoc({
      content: doc,
    }) as StateModel.IFullDoc;
    const formData = this.utils.makeFormData({ document });

    return this.api.updateDocument(collection, formData).pipe(
      concatMap(({ doc_id }) => this.loadDocument(collection, doc_id)),
      concatMap(() => this.loadDocOptions(collection)),
      this._stopLoadingPipe(),
      catchError((err: any) => {
        this.loadingService.stopLoading();
        throw err;
      })
    );
  }

  deleteDoc(): Observable<any> {
    this.loadingService.startLoading();

    const collection = this.state.docType.getValue()
      ?.name as TValidCollectionName;
    const docId = this.state.currentDocId.getValue();

    return this.api.deleteDocument(collection, docId).pipe(
      concatMap(() => this.loadDocOptions(collection)),
      this._clearCurrentDocPipe(),
      this._stopLoadingPipe(),
      catchError((err: any) => {
        this.loadingService.stopLoading();
        throw err;
      })
    );
  }

  // doc state utils

  checkForUnsavedChanges(jsonStr: string): void {
    const contentState =
      this.state.currentDocContent.getValue() as StateModel.IDocContent;
    let hasChanges;
    try {
      const parsed = JSON.parse(jsonStr);
      hasChanges = JSON.stringify(contentState) !== JSON.stringify(parsed);
    } catch (err) {
      hasChanges = false;
    }
    if (hasChanges) this.state.setHasUnsavedChanges(true);
    else this.state.setHasUnsavedChanges(false);
  }

  startNewDoc(template?: StateModel.IDocContent): void {
    this.clearCurrentDoc();
    this.state.setIsNewDoc(true);
    this.state.setCurrentDocId('-1');
    if (template) {
      this.state.setTemplateName(template.name || '');
      this.state.setCurrentDocContent(template);
      this.state.setHasUnsavedChanges(true);
    } else this.state.setCurrentDocContent({ name: '' });
  }

  refreshSelectedDocType(): void {
    //triggers a re-render of the document type selector input value when needed
    const current = this.state.docType.getValue();
    this.state.setDocType(current);
  }

  refreshDocId(): void {
    //triggers a re-render of the document selector input value when needed
    this.state.setCurrentDocId(this.state.currentDocId.getValue());
  }

  refreshDocContent(): void {
    //replaces state of json editor form control with stored (last saved) document state
    const content = this.state.currentDocContent.getValue();
    this.state.setCurrentDocContent(content);
    this.state.setHasUnsavedChanges(false);
  }

  clearCurrentDoc(): void {
    if (this.state.currentDocId.getValue()) this.state.setCurrentDocId('');
    if (this.state.currentDocContent.getValue())
      this.state.setCurrentDocContent(null);
    if (this.state.currentDocMetadata.getValue())
      this.state.setCurrentDocMetadata(null);
    if (this.state.templateName.getValue()) this.state.setTemplateName('');
    this.state.setIsNewDoc(false);
    this.state.setHasUnsavedChanges(false);
  }

  // PRIVATE

  // utils

  private _filterDocTypes = (option: any) => {
    //filter out "version" collections, the rule_set collection, any activity logs, or collections with no docs
    return !/version|activity|rule_set$/.test(option.name) && +option.count > 0;
  };

  // pipeable operators

  /**
   * Sets isLoading state to false
   * @returns {UnaryFunction}
   */
  private _stopLoadingPipe = () => {
    return pipe(
      tap((value: any) => {
        this.loadingService.stopLoading();
        return value;
      })
    );
  };

  /**
   * Run clearCurrentDoc function, but as a pipeable operator
   * @returns {UnaryFunction}
   */
  private _clearCurrentDocPipe = () => {
    return pipe(
      tap((value: any) => {
        this.clearCurrentDoc();
        return value;
      })
    );
  };

  /**
   * Parse and load into state any document that is not a template
   * @returns {UnaryFunction}
   */
  _resolveDocumentPipe = () => {
    return pipe(
      tap((newDoc: StateModel.IFullDoc) => {
        const { _id, content, metadata } = this.docParser.parseFullDoc(
          newDoc
        ) as StateModel.IParsedDoc;
        this.state.setCurrentDocId(_id);
        this.state.setCurrentDocContent(content);
        this.state.setCurrentDocMetadata(metadata);
        return newDoc;
      })
    );
  };

  /**
   * Parse and load into state any document that is being used as a template
   * @returns {UnaryFunction}
   */
  _resolveTemplatePipe = () => {
    return pipe(
      tap((template: StateModel.IFullDoc) => {
        const { content } = this.docParser.parseFullDoc(
          template
        ) as StateModel.IParsedDoc;
        this.startNewDoc(content);
        return template;
      })
    );
  };
}
