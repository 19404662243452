import {
  AfterViewChecked,
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
} from '@angular/core';

@Directive({
  selector: '[rctPrimeSplitButtonIds]',
})
export class PrimeSplitButtonIdsDirective
  implements AfterViewInit, AfterViewChecked
{
  @Input() defaultButtonId: string = '';
  @Input() menuButtonId: string = '';
  @Input() menuId: string = '';

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    const defaultBtn = this.el.nativeElement.querySelector(
      'button.p-splitbutton-defaultbutton'
    );
    const menuBtn = this.el.nativeElement.querySelector(
      'button.p-splitbutton-menubutton'
    );

    defaultBtn.setAttribute('id', this.defaultButtonId);
    menuBtn.setAttribute('id', this.menuButtonId);
  }

  ngAfterViewChecked(): void {
    const menuList = this.el.nativeElement.querySelector('ul.p-menu-list');
    if (menuList) menuList.setAttribute('id', this.menuId);
  }
}
