import {
  AfterViewChecked,
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[rctToolbarBreakpoints]',
})
export class ToolbarBreakpointsDirective
  implements AfterViewChecked, AfterViewInit
{
  current: 'expanded' | 'collapsed';
  validationVisible: boolean;
  toolbarEl: HTMLElement;
  headerTextEl: HTMLElement;
  buttonsEl: HTMLElement;
  titleEl: HTMLElement;
  subtitleEl: HTMLElement;
  validationEl: HTMLElement;

  @Output() collapseEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() expandEvent: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('window:resize', ['$event']) onResizeHandler(): void {
    if (this.shouldExpandValidationButton()) {
      this.expandValidationButton();
    }
    if (this.shouldCollapseValidationButton()) {
      this.collapseValidationButton();
    }

    if (this.shouldCollapse()) {
      this.collapseEvent.emit();
      this.toolbarEl.classList.add('collapsed');
      this.toolbarEl.classList.remove('expanded');
      this.current = 'collapsed';
      this.expandValidationButton();
    } else if (this.shouldExpand()) {
      this.expandEvent.emit();
      this.toolbarEl.classList.add('expanded');
      this.toolbarEl.classList.remove('collapsed');
      this.toolbarEl.classList.remove('collapse-validation');
      this.current = 'expanded';
    }
  }

  constructor(private toolbar: ElementRef) {}

  get toolbarWidth(): number {
    return this.toolbarEl?.offsetWidth || 0;
  }

  get titleWidth(): number {
    return this.headerTextEl?.offsetWidth || 0;
  }

  get buttonsWidth(): number {
    return this.buttonsEl?.offsetWidth || 0;
  }

  get validationWidth(): number {
    return this.validationEl?.offsetWidth || 0;
  }

  ngAfterViewInit(): void {
    this.queryElements();
  }

  ngAfterViewChecked(): void {
    this.queryElements();
    // if validation button has just become visible, run resize handler and mark as visible
    if (!this.validationVisible && this.validationEl) {
      this.validationVisible = true;
      this.onResizeHandler();
      // if validation button has just been removed from the DOM, mark not visible
    } else if (this.validationVisible && !this.validationEl) {
      this.validationVisible = false;
      // if this is immediately after toolbar component mounting, run resize handler
    } else if (!this.current || !this.toolbarWidth) this.onResizeHandler();
  }

  queryElements(): void {
    this.toolbarEl = this.toolbar.nativeElement.querySelector(
      '.p-component.p-toolbar'
    );
    this.headerTextEl =
      this.toolbar.nativeElement.querySelector('.header-text');
    this.buttonsEl = this.toolbar.nativeElement.querySelector(
      '.toolbar-button-container'
    );
    this.titleEl = this.toolbar.nativeElement.querySelector('.editor-title');
    this.subtitleEl =
      this.toolbar.nativeElement.querySelector('.editor-subtitle');
    this.validationEl = this.toolbar.nativeElement.querySelector(
      '#validation-error-btn'
    );
  }

  shouldExpandValidationButton(): boolean {
    return (
      !!this.validationWidth &&
      this.toolbarEl.classList.contains('collapse-validation') &&
      //102 is the approximate pixel width of the collapsible validation button text
      this.titleWidth + this.buttonsWidth + this.validationWidth + 102 < 
        this.toolbarWidth
    );
  }

  shouldCollapseValidationButton(): boolean {
    return (
      !!this.validationWidth &&
      this.current !== 'collapsed' &&
      !this.toolbarEl.classList.contains('collapse-validation') &&
      this.titleWidth + this.buttonsWidth + this.validationWidth >=
        this.toolbarWidth
    );
  }

  shouldCollapse(): boolean {
    return (
      this.titleWidth + this.buttonsWidth + this.validationWidth >=
        this.toolbarWidth &&
      !!this.titleWidth &&
      !!this.buttonsWidth &&
      !!this.toolbarWidth &&
      this.current !== 'collapsed' &&
      this.toolbarEl.classList.contains('collapse-validation')
    );
  }

  shouldExpand(): boolean {
    return (
      (this.titleWidth + this.buttonsWidth + this.validationWidth <
        this.toolbarWidth ||
        (!this.titleWidth && !this.buttonsWidth && !this.toolbarWidth)) &&
      this.current !== 'expanded'
    );
  }

  /**
   * Add class 'collapse-validation', which will hide button text (leaving just validation error count and icon)
   */
  collapseValidationButton(): void {
    this.toolbarEl.classList.add('collapse-validation');
  }

  /**
   * Remove class 'collapse-validation', which will add button text back to button
   */
  expandValidationButton(): void {
    this.toolbarEl.classList.remove('collapse-validation');
  }
}
