<div class="rct-copy-button" [ngStyle]="buttonStyle">
  <button
    *ngIf="!this.used && !this.error"
    pButton
    type="button"
    icon="pi pi-clone"
    [ngStyle]="iconStyle"
    class="rct-copy-button-icon p-button-text"
    [class]="iconStyleClass"
    [title]="title"
    (click)="copyToClipboard()"
  ></button>
  <small
    *ngIf="this.used && !this.error"
    class="rct-copy-button-success"
    [class]="successMessageStyleClass"
    [ngStyle]="successStyle"
    >{{successMessage}}</small
  >
  <small
    *ngIf="this.error"
    class="p-error rct-copy-button-error"
    [class]="errorMessageStyleClass"
    [ngStyle]="errorStyle"
    >{{errorMessage}}</small
  >
</div>
