import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  /**
   * Appends all key/value pairs from an object onto a new FormData object
   * @param data an object
   * @returns {FormData} a new FormData object containing all (stringified) data from passed-in object
   */
  makeFormData = (data: { [key: string]: any }): FormData => {
    const formData = new FormData();
    for (const key in data) {
      let value = data[key];
      if (typeof value === 'object') value = JSON.stringify(value);
      formData.append(key, value);
    }
    return formData;
  };

  /**
   * Coerce any type of value to a string in optimal format
   * @param value any value
   * @returns a string version of the value (empty string for null/undefined)
   */
  coerceToString = (value: any) => {
    if (value === null || value === undefined) return '';
    if (typeof value === 'string') return value;
    return JSON.stringify(value);
  }
}
