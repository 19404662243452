<div
  id="app-container"
  class="grid p-3 grid-nogutter"
  style="width: 100%; height: 100%"
>
  <p-card class="col-12 lg:col-10 lg:col-offset-1 shadow-5">
    <main class="px-2">
      <h1 id="editor-title">{{ title }}</h1>
      <p-tabView (onChange)="clearMessages()">
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="pi pi-file"></i>
            <span id="document-editor-tab" class="pl-2 tab-header-text"
              >Document Editor</span
            >
          </ng-template>
          <rct-document-editor></rct-document-editor>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="pi pi-list"></i>
            <span id="ruleset-editor-tab" class="pl-2 tab-header-text"
              >RuleSet Editor</span
            >
          </ng-template>
          <ng-template pTemplate="content">
            <rct-ruleset-editor></rct-ruleset-editor>
          </ng-template>
        </p-tabPanel>
      </p-tabView>
    </main>
  </p-card>
</div>

<p-confirmDialog
  header="Unsaved Changes"
  icon="pi pi-exclamation-triangle"
  defaultFocus="none"
  rejectButtonStyleClass="p-button-secondary"
></p-confirmDialog>
<p-toast id="toast-message"></p-toast>
<rct-full-page-spinner></rct-full-page-spinner>