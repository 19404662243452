import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[rctPrimePicklistIds]',
})
export class PrimePicklistIdsDirective implements AfterViewInit {
  @Input() targetFilterId: string = '';
  @Input() sourceFilterId: string = '';
  @Input() moveToTargetButtonId: string = '';
  @Input() moveToSourceButtonId: string = '';
  @Input() moveAllToTargetButtonId: string = '';
  @Input() moveAllToSourceButtonId: string = '';
  @Input() sourceListboxId: string = '';
  @Input() targetListboxId: string = '';

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    const nativeElement = this.el.nativeElement;
    
    const sourceFilter = nativeElement.querySelector(
      '.p-picklist-source-wrapper .p-picklist-filter-input.p-inputtext'
    );
    const targetFilter = nativeElement.querySelector(
      '.p-picklist-target-wrapper .p-picklist-filter-input.p-inputtext'
    );
    const moveToTargetButton = nativeElement.querySelector(
      '.p-picklist-transfer-buttons button.p-button-icon-only:first-child'
    );
    const moveToSourceButton = nativeElement.querySelector(
      '.p-picklist-transfer-buttons button.p-button-icon-only:nth-child(3)'
    );
    const moveAllToTargetButton = nativeElement.querySelector(
      '.p-picklist-transfer-buttons button.p-button-icon-only:nth-child(2)'
    );
    const moveAllToSourceButton = nativeElement.querySelector(
      '.p-picklist-transfer-buttons button.p-button-icon-only:last-child'
    );
    const sourceListbox = nativeElement.querySelector(
      'ul.p-picklist-list.p-picklist-source'
    );
    const targetListbox = nativeElement.querySelector(
      'ul.p-picklist-list.p-picklist-target'
    );

    if (sourceFilter) sourceFilter.setAttribute('id', this.sourceFilterId);
    if (targetFilter) targetFilter.setAttribute('id', this.targetFilterId);
    if (moveToTargetButton)
      moveToTargetButton.setAttribute('id', this.moveToTargetButtonId);
    if (moveToSourceButton)
      moveToSourceButton.setAttribute('id', this.moveToSourceButtonId);
    if (moveAllToTargetButton)
      moveAllToTargetButton.setAttribute('id', this.moveAllToTargetButtonId);
    if (moveAllToSourceButton)
      moveAllToSourceButton.setAttribute('id', this.moveAllToSourceButtonId);
    if (sourceListbox) sourceListbox.setAttribute('id', this.sourceListboxId);
    if (targetListbox) targetListbox.setAttribute('id', this.targetListboxId);
  }
}
