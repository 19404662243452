import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { AutocompleteService } from 'src/app/core/autocomplete/autocomplete.service';
import { FormatterService } from 'src/app/core/formatter/formatter.service';
import { RuleSetEditorFacadeService } from 'src/app/facades/ruleset-editor-facade.service';
import * as StateModel from 'src/app/core/models/state.model';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { IDocument } from 'src/app/core/models/api.model';
import { JsonViewerComponent } from '../json-viewer/json-viewer.component';
import {
  invalidTypeValidator,
  invalidYearValidator,
  cannotBeEmptyValidator,
} from 'src/app/validators/form-validators';
import { ValidationOverlayTriggerService } from 'src/app/core/validation-overlay-trigger/validation-overlay-trigger.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'rct-ruleset-editor',
  templateUrl: './ruleset-editor.component.html',
  styleUrls: ['./ruleset-editor.component.scss'],
})
export class RuleSetEditorComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];

  ruleSetForm: FormGroup;
  controls: { [key: string]: FormControl | FormGroup } = {};

  //state
  rulesLoading: boolean;
  ruleSetOptions: StateModel.IRuleSetOption[] = [];
  selectedRuleSet: StateModel.IFullRuleSet;
  currentRuleSetId: string;
  currentRuleSetName: string;
  ruleSetTypeOptions: string[] = [];
  isNew: boolean = false;
  ruleSetSuggestions: StateModel.IRuleSetOption[] = [];
  unsavedChanges: boolean = false;

  validYearRange: [number, number] = [2021, 2080];
  yearOptions: string[] = [];

  ruleOptions: StateModel.IDocOption[] = [];
  selectedRules: StateModel.IDocOption[] = [];

  //validators
  nameValidators: ValidatorFn[] = [
    Validators.required,
    cannotBeEmptyValidator(),
  ];
  yearValidators: ValidatorFn[] = [
    Validators.required,
    invalidYearValidator(...this.validYearRange),
  ];
  typeValidators: ValidatorFn[] = [Validators.required];
  descriptionValidators: ValidatorFn[] = [
    Validators.required,
    cannotBeEmptyValidator(),
  ];

  //validation
  validating: boolean = false;
  validationStatus: StateModel.TValidationStatus | '' = '';
  validationIsStale: boolean = false;

  //errors
  ruleSetError: boolean;
  ruleError: string; // the id of the rule that caused the error

  constructor(
    private facade: RuleSetEditorFacadeService,
    private fb: FormBuilder,
    private autocompleteService: AutocompleteService,
    private formatterService: FormatterService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private validationOverlayTrigger: ValidationOverlayTriggerService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.initStateSubscribers();
    this.facade.loadRuleSetTypes().subscribe((validTypes: string[]) => {
      this.controls.type.setValidators([
        ...this.typeValidators,
        invalidTypeValidator(validTypes),
      ]);
    });
    this.facade.loadRuleSetOptions().subscribe();
  }

  ngOnDestroy(): void {
    this.subs.forEach((s: Subscription) => s.unsubscribe());
  }

  initForm(): void {
    this.ruleSetForm = this.fb.group({
      selectedRuleSet: [''],
      ruleSetContent: this.fb.group({
        name: ['', this.nameValidators],
        year: ['', this.yearValidators],
        type: ['', this.typeValidators],
        description: ['', this.descriptionValidators],
        rulesRef: [[]],
      }),
    });

    this.controls = {
      selectedRuleSet: this.ruleSetForm.get('selectedRuleSet') as FormControl,
      ruleSetContent: this.ruleSetForm.controls.ruleSetContent as FormGroup,
      name: (this.ruleSetForm.get('ruleSetContent') as FormGroup).controls
        .name as FormControl,
      description: (this.ruleSetForm.get('ruleSetContent') as FormGroup)
        .controls.description as FormControl,
      type: (this.ruleSetForm.get('ruleSetContent') as FormGroup).controls
        .type as FormControl,
      year: (this.ruleSetForm.get('ruleSetContent') as FormGroup).controls
        .year as FormControl,
      rulesRef: (this.ruleSetForm.get('ruleSetContent') as FormGroup).controls
        .rulesRef as FormControl,
    };

    this.controls.ruleSetContent.valueChanges.subscribe((val) => {
      this.facade.checkForUnsavedChanges(val);
    });

    this.generateYearOptions();
  }

  initStateSubscribers() {
    this.subs.push(
      this.facade.getRulesLoading().subscribe((rulesLoading: boolean) => {
        this.rulesLoading = rulesLoading;
      })
    );

    this.subs.push(
      this.facade
        .getRuleSetOptions()
        .subscribe((options: StateModel.IRuleSetOption[]) => {
          this.ruleSetOptions = options;
        })
    );

    this.subs.push(
      this.facade
        .getSelectedRuleSetOption()
        .subscribe((option: StateModel.IRuleSetOption | null) => {
          this.controls.selectedRuleSet.setValue(option);
        })
    );

    this.subs.push(
      this.facade
        .getAvailableRules()
        .subscribe((rules: StateModel.IDocOption[]) => {
          this._partitionRules(rules);
        })
    );

    this.subs.push(
      this.facade.getRuleSetTypes().subscribe((types: string[]) => {
        this.ruleSetTypeOptions = types;
      })
    );

    this.subs.push(
      this.facade.getIsNew().subscribe((isNew: boolean) => {
        this.isNew = isNew;
      })
    );

    this.subs.push(
      this.facade.getHasUnsavedChanges().subscribe((c: boolean) => {
        this.unsavedChanges = c;
      })
    );

    this.subs.push(
      this.facade.getCurrentRuleSetId().subscribe((id: string) => {
        this.currentRuleSetId = id;
      })
    );

    this.subs.push(
      this.facade
        .getCurrentRuleSetProperties()
        .subscribe((properties: StateModel.TCurrentRuleSetPropertiesState) => {
          this.controls.name.setValue(properties ? properties.name : '');
          this.currentRuleSetName = properties ? properties.name : '';
          this.controls.type.setValue(properties ? properties.type : '');
          this.controls.year.setValue(properties ? properties.year : '');
          this.controls.description.setValue(
            properties ? properties.description : ''
          );
        })
    );

    this.subs.push(
      this.facade
        .getCurrentRuleSetRulesRef()
        .subscribe((rulesRef: string[]) => {
          this.controls.rulesRef.setValue(rulesRef);
          this._partitionRules();
        })
    );

    this.subs.push(
      this.facade
        .getValidationStatus()
        .subscribe((status: StateModel.TValidationStatus | '') => {
          this.validationStatus = status;
        })
    );

    this.subs.push(
      this.facade.getValidationIsStale().subscribe((isStale: boolean) => {
        this.validationIsStale = isStale;
      })
    );
  }

  /**
   * Uses set max and min years to generate array of year strings
   * @returns Array of year strings
   */
  generateYearOptions(): void {
    const [min, max] = this.validYearRange;
    const options: string[] = [];
    for (let y = min; y <= max; y++) {
      options.push(y.toString());
    }
    this.yearOptions = options;
  }

  /**
   * Filter RuleSet options based on user-entered query string
   * @param query user-entered query string
   */
  getRuleSetSuggestions(query: string): void {
    this.ruleSetSuggestions = this.autocompleteService.getSuggestions(
      this.ruleSetOptions,
      query,
      'name'
    ) as StateModel.IRuleSetOption[];
  }

  /**
   * Call facade method to set current RuleSet
   * @param event the selected RuleSet option
   */
  handleSelectRuleSet(event: any): void {
    this.messageService.clear();
    this.facade
      .setCurrentRuleSet(event._id)
      .subscribe((updatedAvailableRules: StateModel.IFullDoc[]) => {
        this._partitionRules(updatedAvailableRules);
      });
  }

  /**
   * Call facade method to update RuleSet with current value of form
   */
  handleSaveRuleSet(): void {
    const { rulesRef, ...properties } = this.controls.ruleSetContent.value;
    const response = this.isNew
      ? this.facade.createNewRuleSet(properties, rulesRef)
      : this.facade.updateCurrentRuleSet(properties, rulesRef);

    response.subscribe(
      (val) => {
        this.messageService.clear();
        this.messageService.add({
          severity: 'success',
          summary: 'RuleSet saved',
        });
        // this._clearErrors();
      },
      (err) => {
        console.error(err);
        this.messageService.clear();
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'RuleSet could not be saved. Please try again.',
        });
      }
    );
  }

  /**
   * Call facade method to discard any unsaved changes (or the entire document if new) and generate toast message
   */
  handleDiscardChanges(): void {
    try {
      let summary;
      if (this.isNew) {
        summary = 'RuleSet discarded.';
        this.facade.clearRuleSet();
      } else {
        summary = 'Changes discarded.';
        this.facade.refreshRuleSet();
      }
      this.messageService.clear();
      this.messageService.add({
        severity: 'warn',
        summary,
      });
      // this._clearErrors();
    } catch (err) {
      this.messageService.clear();
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'There was an issue. Please try again.',
      });
    }
  }

  /**
   * Reset form inputs to pristine/untouched state and call facade method to start new RuleSet
   */
  handleStartNew(): void {
    this.messageService.clear();
    this._resetFormFieldState();
    this.facade.clearValidationResults();
    this.facade.startNewRuleSet().subscribe();
    //this._clearErrors()
  }

  /**
   * Call facade method to retrieve the given rule
   * @param id string id of the rule to view
   */
  handleViewRule(event: any, id: string): void {
    event.stopPropagation();
    this.ruleError = '';
    this.facade.getRuleById(id).subscribe(
      (rule: any) => {
        this.openRuleViewerDialog(rule);
      },
      (err: any) => {
        console.error(err);
        this.ruleError = id;
      }
    );
  }

  /**
   * Map the rule documents added by the user to the rulesRef formControl value
   * @param added the single document or array of documents the user added to the RuleSet
   */
  handleAddRule(added: StateModel.IFullDoc | StateModel.IFullDoc[]): void {
    const newRules = [...this.controls.rulesRef.value];
    if (Array.isArray(added)) {
      for (const rule of added) {
        newRules.push(rule._id);
      }
    } else {
      newRules.push(added._id);
    }
    this.controls.rulesRef.setValue(newRules);
  }

  /**
   * Map the rule documents removed by the user to the rulesRef formControl value
   * @param removed the single document or array of documents the user removed from the RuleSet
   */
  handleRemoveRule(removed: StateModel.IFullDoc | StateModel.IFullDoc[]): void {
    const filteredRules = this.controls.rulesRef.value.filter((id: string) => {
      if (Array.isArray(removed)) return !removed.find((r) => r._id === id);
      else return id !== removed._id;
    });

    this.controls.rulesRef.setValue(filteredRules);
  }

  /**
   * Call facade method to delete RuleSet and trigger success or error message depending on result
   */
  handleDeleteRuleSet(): void {
    this.messageService.clear();
    this.facade.deleteCurrentRuleSet().subscribe(
      (val) => {
        this.messageService.add({
          severity: 'warn',
          summary: 'RuleSet deleted.',
        });
        // this._clearErrors();
      },
      (err) =>
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'The RuleSet could not be deleted. Please try again.',
        })
    );
  }

  /**
   * Call facade method to clone current RuleSet
   */
  handleCloneRuleSet(): void {
    this.messageService.clear();
    this.facade.cloneCurrentRuleSet();
    this.controls.selectedRuleSet.setValue(null);
  }

  /**
   * Call facade method to validate current RuleSet
   */
  handleValidateRuleSet(): void {
    this.messageService.clear();
    this.validating = true;
    this.facade
      .validateCurrentRuleSet()
      .pipe(
        tap((result: StateModel.IValidationResult) => {
          this.validating = false;
        })
      )
      .subscribe(
        (result: StateModel.IValidationResult) => {
          if (result.result === 'success') {
            this.messageService.add({
              severity: 'success',
              summary: 'Validation Complete',
              detail: 'Ready to publish.',
              sticky: true,
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Validation Failed',
              detail: `${result.errors.length} Error${
                result.errors.length === 1 ? '' : 's'
              } Found.`,
              sticky: true,
            });
          }
        },
        (err: any) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Validation could not be completed',
            detail: `Server returned error with status ${err.status}`,
            sticky: true,
          });
        }
      );
  }

  // Confirmation methods

  /**
   * Open a confirmation modal for user to confirm overwriting unsaved changes
   * @param ruleSet the RuleSet the user is attempting to select
   */
  confirmSelectRuleSet(ruleSet: any): void {
    if (ruleSet._id === this.currentRuleSetId) return;
    this.confirmationService.confirm({
      message: `Selecting a new RuleSet will discard ${
        this.isNew ? 'this RuleSet' : 'your unsaved changes'
      }. Are you sure you want to continue?`,
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button',
      accept: () => {
        this.handleSelectRuleSet(ruleSet);
        // this._clearErrors();
      },
      reject: () => {
        this.facade.refreshRuleSet('id');
      },
    });
  }

  /**
   * Open a confirmation modal for user to confirm discarding unsaved changes
   */
  confirmDiscardChanges(): void {
    this.confirmationService.confirm({
      message: `Are you sure you want to discard ${
        this.isNew ? 'this RuleSet' : 'your unsaved changes'
      }?`,
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button',
      accept: () => {
        this.handleDiscardChanges();
        // this._clearErrors();
      },
    });
  }

  /**
   * Open a confirmation modal for user to confirm starting new RuleSet when doing so would discard changes
   */
  confirmStartNew(): void {
    this.confirmationService.confirm({
      message: `Creating a new RuleSet will discard ${
        this.isNew ? 'this RuleSet' : 'your unsaved changes'
      }. Are you sure you want to continue?`,
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button',
      accept: () => {
        this.handleStartNew();
        // this._clearErrors();
      },
    });
  }

  /**
   * Open a confirmation modal for user to confirm deleting RuleSet
   */
  confirmDeleteRuleSet(): void {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete this RuleSet? This action cannot be undone.`,
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button',
      accept: () => {
        this.handleDeleteRuleSet();
      },
    });
  }

  // Modal methods

  /**
   * Open a PrimeNG dialog containing the JsonViewerComponent and pass the given rule document
   * @param rule a full rule document
   */
  openRuleViewerDialog(rule: IDocument): void {
    const ref = this.dialogService.open(JsonViewerComponent, {
      header: `${rule.name} Rule`,
      width: '500px',
      height: '60vh',
      data: { json: rule },
      styleClass: 'rule-viewer-dialog',
      contentStyle: { width: '100%', height: '100%' },
    });
  }

  // PRIVATE

  /**
   * Separate list of available rule documents into those in the RuleSet and those not in the RuleSet
   * @param rules all currently available rule documents
   */
  private _partitionRules(rules?: StateModel.IDocOption[]): void {
    if (!rules) rules = this.ruleOptions.concat(this.selectedRules);

    const filteredOptions: any[] = [];
    const selectedRules: any[] = [];

    for (const rule of rules) {
      if (this.controls.rulesRef.value.includes(rule._id)) {
        selectedRules.push(rule);
      } else {
        filteredOptions.push(rule);
      }
    }

    this.selectedRules = selectedRules;
    this.ruleOptions = filteredOptions;
  }

  /**
   * Reset state of form inputs to 'pristine' and 'untouched'
   */
  private _resetFormFieldState(): void {
    const toReset = (this.controls.ruleSetContent as FormGroup).controls;
    for (const control in toReset) {
      toReset[control].markAsUntouched();
      toReset[control].markAsPristine();
    }
  }
}
