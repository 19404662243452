import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { DocumentEditorFacade } from '../../facades/document-editor-facade.service';
import { FormatterService } from '../../core/formatter/formatter.service';
import * as DocumentEditorModel from './document-editor.model';
import { ConfirmationService, MessageService, MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TemplateSelectorComponent } from '../template-selector/template-selector.component';
import { AutocompleteService } from 'src/app/core/autocomplete/autocomplete.service';
import { UtilsService } from 'src/app/core/utils/utils.service';

@Component({
  selector: 'rct-document-editor',
  templateUrl: './document-editor.component.html',
  styleUrls: ['./document-editor.component.scss'],
})
export class DocumentEditorComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private subs: Subscription[] = [];
  @ViewChildren(JsonEditorComponent, { emitDistinctChangesOnly: true })
  jsonEditorQueryList: QueryList<JsonEditorComponent>; //uses ViewChildren to respond to QueryList changes when editor component is added to dom
  private jsonEditorMode: string;
  private aceEditorMaxLines: number = 25;

  //form
  //@ts-ignore
  form: FormGroup;
  controls: { [key: string]: FormControl } = {};
  jsonEditorConfig: JsonEditorOptions = new JsonEditorOptions();

  //state
  docTypeOptions: any[] = [];
  docOptions: any[] = [];
  docTypeSuggestions: any[] = [];
  docSuggestions: any[] = [];
  selectedDocTypeOption: any;
  currentDocId: string;
  currentDocName: string;
  isNewDoc: boolean;
  templateName: string;
  unsavedChanges: boolean = false;

  //these should be state properties - move to store
  docTypeError: boolean = false;
  docError: boolean = false;
  jsonError: boolean = false;

  constructor(
    private fb: FormBuilder,
    private documentEditorFacade: DocumentEditorFacade,
    private autocompleteService: AutocompleteService,
    private formatter: FormatterService,
    private utils: UtilsService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private dialogService: DialogService
  ) {}

  get jsonEditor() {
    return this.jsonEditorQueryList?.first || null;
  }

  //lifecycle

  ngOnInit() {
    this.setJsonEditorOptions();
    this.initForm();
    this.initStateSubscribers();
    this.documentEditorFacade.loadDocTypes().subscribe();
  }

  ngAfterViewInit() {
    this.subs.push(
      this.jsonEditorQueryList.changes.subscribe((c: any) => {
        if (c.first) this.styleJsonEditor();
      })
    );

    this.aceEditorMaxLines = this._calculateAceEditorMaxLines();
  }

  ngOnDestroy() {
    this.subs.forEach((s: Subscription) => {
      s.unsubscribe();
    });
    this.subs = [];
    this.jsonEditor.destroy();
  }

  // init

  initForm() {
    this.form = this.fb.group({
      docType: [''],
      selectedDocOption: [''],
      docContent: [''],
    });
    this.controls = {
      docType: this.form.get('docType'),
      selectedDocOption: this.form.get('selectedDocOption'),
      docContent: this.form.get('docContent'),
    };
    this.subs.push(
      this.documentEditorFacade
        .getSelectedDocTypeOption()
        .subscribe((docType: DocumentEditorModel.IDocTypeOption | null) => {
          this.controls.docType.setValue(docType);
          this._waitForEditor((editor: JsonEditorComponent) => {
            editor.setName(this.formatter.snakeCaseToCamelCase(docType?.name));
          });
        })
    );
    this.subs.push(
      this.documentEditorFacade
        .getSelectedDocOption()
        .subscribe((docOption: DocumentEditorModel.IDocOption | null) => {
          //this is an "option" object containing just the _id and name properties of the doc
          this.controls.selectedDocOption.setValue(docOption);
        })
    );
    this.subs.push(
      this.documentEditorFacade
        .getSelectedDocContent()
        .subscribe((content: DocumentEditorModel.IDocContent | null) => {
          //this is the entire document sans _id property to prevent the user from changing it
          this.controls.docContent.setValue(content);
          if (this.isNewDoc) this._setJsonEditorMode('code');
          if (this.jsonEditor) this.styleJsonEditor();
        })
    );
  }

  initStateSubscribers() {
    this.subs.push(
      this.documentEditorFacade
        .getDocTypeOptions()
        .subscribe((options: DocumentEditorModel.IDocTypeOption[]) => {
          this.docTypeOptions = options;
        })
    );
    this.subs.push(
      this.documentEditorFacade
        .getDocOptions()
        .subscribe((options: DocumentEditorModel.IDocOption[]) => {
          this.docOptions = options;
        })
    );
    this.subs.push(
      this.documentEditorFacade
        .getSelectedDocTypeOption()
        .subscribe((dt: DocumentEditorModel.IDocTypeOption | null) => {
          this.selectedDocTypeOption = dt;
        })
    );
    this.subs.push(
      this.documentEditorFacade.getCurrentDocId().subscribe((id: string) => {
        this.currentDocId = id;
      })
    );
    this.subs.push(
      this.documentEditorFacade
        .getCurrentDocName()
        .subscribe((name: string) => {
          this.currentDocName = name;
        })
    );
    this.subs.push(
      this.documentEditorFacade
        .getHasUnsavedChanges()
        .subscribe((c: boolean) => {
          this.unsavedChanges = c;
        })
    );
    this.subs.push(
      this.documentEditorFacade.getIsNewDoc().subscribe((n: boolean) => {
        this.isNewDoc = n;
      })
    );
    this.subs.push(
      this.documentEditorFacade.getTemplateName().subscribe((name: string) => {
        this.templateName = name;
      })
    );
  }

  //jsoneditor modification

  setJsonEditorOptions() {
    this.jsonEditorConfig.modes = ['code', 'tree'];
    //@ts-ignore - the angular jsoneditor wrapper component doesn't recognize this as valid option but it is
    this.jsonEditorConfig.colorPicker = false;
    this.jsonEditorConfig.enableTransform = false;
    this.jsonEditorConfig.onChangeText = (jsonstr: string) => {
      this.documentEditorFacade.checkForUnsavedChanges(jsonstr);
    };
    this.jsonEditorConfig.onModeChange = (mode: string) => {
      this.jsonEditorMode = mode;
      this.styleJsonEditor();
      this._setAceEditorMaxLines();
    };
    this.jsonEditorConfig.onError = (err: any) => {
      this.jsonError = true;
    };
    this.jsonEditorConfig.onValidate = (json: Object): any => {
      this.jsonError = false;
    };
  }

  styleJsonEditor(): void {
    const menu = this.jsonEditor
      .getEditor()
      .container.querySelector('div.jsoneditor-menu');

    const expandAllBtn = menu.querySelector('button.jsoneditor-expand-all');
    const collapseAllBtn = menu.querySelector('button.jsoneditor-collapse-all');
    const sortBtn = menu.querySelector('button.jsoneditor-sort');
    const undoBtn = menu.querySelector('button.jsoneditor-undo');
    const redoBtn = menu.querySelector('button.jsoneditor-redo');

    if (expandAllBtn) {
      expandAllBtn.style.backgroundImage = 'none';
      expandAllBtn.innerHTML =
        '<span class="inline-flex flex-column justify-content-center"><i class="pi pi-chevron-up" style="font-size: 0.75rem"></i><i class="pi pi-chevron-down" style="font-size: 0.75rem"></i></span>';
    }
    if (collapseAllBtn) {
      collapseAllBtn.style.backgroundImage = 'none';
      collapseAllBtn.innerHTML =
        '<span class="inline-flex flex-column-reverse justify-content-center"><i class="pi pi-chevron-up" style="font-size: 0.75rem"></i><i class="pi pi-chevron-down" style="font-size: 0.75rem"></i></span>';
    }
    if (sortBtn) {
      sortBtn.style.backgroundImage = 'none';
      sortBtn.innerHTML = '<i class="pi pi-sort-alt"></i>';
    }
    if (undoBtn) {
      undoBtn.style.backgroundImage = 'none';
      undoBtn.innerHTML = '<i class="pi pi-undo"></i>';
    }
    if (redoBtn) {
      redoBtn.style.backgroundImage = 'none';
      redoBtn.innerHTML = '<i class="pi pi-refresh"></i>';
    }
  }

  //event handlers

  handleSelectDocType(event: any) {
    if (event === this.selectedDocTypeOption) return;
    this.documentEditorFacade.setDocType(event).subscribe(
      (result: any) => {
        this.docTypeError = false;
      },
      (err: any) => {
        this.docTypeError = true;
      }
    );
  }

  handleSelectDoc(event: any) {
    if (event._id === this.currentDocId) return;

    this.documentEditorFacade.setCurrentDoc(event._id).subscribe(
      (result: any) => {
        this._clearErrors();
        this._setJsonEditorMode('tree');
      },
      (err: any) => {
        this.docError = true;
        throw err;
      }
    );
  }

  handleAddNew(event: any): void {
    if (this.selectedDocTypeOption.name === 'rule') this.openTemplateDialog();
    else this.documentEditorFacade.startNewDoc();
    this._clearErrors();
  }

  handleSaveDoc(): void {
    const docContent = this.controls.docContent.value;
    docContent.name = this.utils.coerceToString(docContent.name);
    let response;

    if (this.currentDocId && this.currentDocId !== '-1') {
      response = this.documentEditorFacade.updateCurrentDoc(docContent);
    } else {
      response = this.documentEditorFacade.saveNewDoc(docContent);
    }

    response.subscribe(
      (val) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Document saved',
        });
        this._clearErrors();
      },
      (err) => {
        console.error(err);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Document could not be saved. Please try again.',
        });
      }
    );
    this._setAceEditorMaxLines();
  }

  handleDiscardChanges(): void {
    try {
      let summary;
      if (this.isNewDoc) {
        summary = 'Document discarded.';
        this.documentEditorFacade.clearCurrentDoc();
      } else {
        summary = 'Changes discarded.';
        this.documentEditorFacade.refreshDocContent();
      }
      this.messageService.add({
        severity: 'warn',
        summary,
      });
      this._clearErrors();
    } catch (err) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'There was an issue. Please try again.',
      });
    }

    this._setAceEditorMaxLines();
  }

  handleDeleteDoc(): void {
    this.documentEditorFacade.deleteDoc().subscribe(
      (val) => {
        this.messageService.add({
          severity: 'warn',
          summary: 'Document deleted.',
        });
        this._clearErrors();
      },
      (err) =>
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'The document could not be deleted. Please try again.',
        })
    );
  }

  //confirmation methods

  confirmDeleteDoc(): void {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete this document? This action cannot be undone.`,
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button',
      accept: () => {
        this.handleDeleteDoc();
      },
    });
  }

  confirmDiscardChanges(): void {
    this.confirmationService.confirm({
      message: `Are you sure you want to discard ${
        this.isNewDoc ? 'this document' : 'your unsaved changes'
      }?`,
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button',
      accept: () => {
        this.handleDiscardChanges();
        this._clearErrors();
      },
    });
  }

  confirmSelectDoc(event: any): void {
    if (event._id === this.currentDocId) return;
    this.confirmationService.confirm({
      message: `Selecting a new document will discard ${
        this.isNewDoc ? 'this document' : 'your unsaved changes'
      }. Are you sure you want to continue?`,
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button',
      accept: () => {
        this.handleSelectDoc(event);
        this._clearErrors();
      },
      reject: () => {
        this.documentEditorFacade.refreshDocId();
      },
    });
  }

  confirmSelectDocType(event: any): void {
    if (event === this.selectedDocTypeOption) return;
    this.confirmationService.confirm({
      message: `Selecting a new document type will discard ${
        this.isNewDoc ? 'this document' : 'your unsaved changes'
      }. Are you sure you want to continue?`,
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button',
      accept: () => {
        this.handleSelectDocType(event);
        this._clearErrors();
      },
      reject: () => {
        this.documentEditorFacade.refreshSelectedDocType();
      },
    });
  }

  confirmAddNew(event: any): void {
    this.confirmationService.confirm({
      message: `Creating a new document will discard ${
        this.isNewDoc ? 'this document' : 'your unsaved changes'
      }. Are you sure you want to contnue?`,
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button',
      accept: () => {
        this.documentEditorFacade.startNewDoc();
        this._clearErrors();
      },
    });
  }

  //label-makers

  makeDocTypeLabel(): string {
    const formattedDocType = this.formatter.formatRuleSet(
      this.formatter.snakeCaseToWords(this.selectedDocTypeOption.name)
    );
    const article = /aeiou/i.test(formattedDocType[0]) ? 'an' : 'a';
    return `Select ${article} ${formattedDocType} document:`;
  }

  makeEditorTitle(): string {
    if (!this.selectedDocTypeOption) return '';
    const formattedDocType = this.formatter.formatRuleSet(
      this.formatter.capitalizeAll(
        this.formatter.snakeCaseToWords(this.selectedDocTypeOption.name)
      )
    );
    if (this.templateName) {
      return `New ${this.templateName} Rule`;
    } else if (this.isNewDoc) {
      return `New ${formattedDocType} Document`;
    } else if (!this.currentDocName) {
      return `Unnamed ${formattedDocType}`;
    }
    return `${this.currentDocName} ${formattedDocType}`;
  }

  //autocomplete search methods

  getDocTypeSuggestions(query: string): void {
    this.docTypeSuggestions = this.autocompleteService.getSuggestions(
      this.docTypeOptions,
      query,
      'displayName'
    );
  }

  getDocSuggestions(query: string): void {
    this.docSuggestions = this.autocompleteService.getSuggestions(
      this.docOptions,
      query,
      'name'
    );
  }

  // modal logic

  openTemplateDialog(): void {
    const ref = this.dialogService.open(TemplateSelectorComponent, {
      header: 'Choose a rule template',
      width: '60vw',
      height: '60vh',
      styleClass: 'template-selector-dialog',
      contentStyle: { width: '100%', height: '100%' },
    });
    ref.onClose.subscribe((templateId: string) => {
      if (!templateId) return;
      if (templateId === '-1') this.documentEditorFacade.startNewDoc();
      else this.documentEditorFacade.setCurrentTemplate(templateId).subscribe();
    });
  }

  //private

  private _clearErrors = (): void => {
    this.docError = false;
    this.docTypeError = false;
    this.jsonError = false;
  };

  private _setJsonEditorMode = (mode: 'code' | 'tree'): void => {
    if (this.jsonEditor) this.jsonEditor.getEditor().setMode(mode);
    else
      this._waitForEditor((editor: any) => {
        editor.getEditor().setMode(mode);
      });
  };

  private _calculateAceEditorMaxLines = () => {
    if (window.innerHeight)
      return Math.floor(((window.innerHeight / 100) * 50) / 16);
    return this.aceEditorMaxLines;
  };

  private _setAceEditorMaxLines = (
    lines: number = this.aceEditorMaxLines
  ): void => {
    if (this.jsonEditorMode === 'code') {
      if (this.jsonEditor) {
        this.jsonEditor
          .getEditor()
          .aceEditor.setOptions({ maxLines: lines, minLines: lines });
      } else
        this._waitForEditor((editor: any) => {
          editor
            .getEditor()
            .aceEditor.setOptions({ maxLines: lines, minLines: lines });
        });
    }
  };

  private _waitForEditor = (
    callback: (editor: JsonEditorComponent) => void
  ): void => {
    if (this.jsonEditor) callback(this.jsonEditor);
    else
      this.jsonEditorQueryList?.changes.pipe(first()).subscribe((list) => {
        callback(this.jsonEditor);
      });
  };
}
