import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as StateModel from '../models/state.model';

@Injectable({
  providedIn: 'root',
})
export class RuleSetEditorStateService {
  // state properties

  private ruleSetOptions$: BehaviorSubject<StateModel.IRuleSetOption[]> =
    new BehaviorSubject<StateModel.IRuleSetOption[]>([]);

  private currentRuleSetId$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  private currentRuleSetProperties$: BehaviorSubject<StateModel.TCurrentRuleSetPropertiesState> =
    new BehaviorSubject<StateModel.TCurrentRuleSetPropertiesState>(null);

  private currentRuleSetRulesRef$: BehaviorSubject<string[]> =
    new BehaviorSubject<string[]>([]);

  private currentRuleSetMetadata$: BehaviorSubject<StateModel.TCurrentRuleSetMetadataState> =
    new BehaviorSubject<StateModel.TCurrentDocMetadataState>(null);

  private currentAvailableRules$: BehaviorSubject<StateModel.IDocOption[]> =
    new BehaviorSubject<StateModel.IDocOption[]>([]);

  private ruleSetTypes$: BehaviorSubject<string[]> = new BehaviorSubject<
    string[]
  >([]);

  private isNew$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private isPublished$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private hasUnsavedChanges$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private validationResult$: BehaviorSubject<StateModel.TValidationResultState> =
    new BehaviorSubject<StateModel.TValidationResultState>(null);

  private validationIsStale$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private rulesLoading$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  // getters

  get ruleSetOptions(): BehaviorSubject<StateModel.IRuleSetOption[]> {
    return this.ruleSetOptions$;
  }

  get currentRuleSetId(): BehaviorSubject<string> {
    return this.currentRuleSetId$;
  }

  get currentRuleSetProperties(): BehaviorSubject<StateModel.TCurrentRuleSetPropertiesState> {
    return this.currentRuleSetProperties$;
  }

  get currentRuleSetRulesRef(): BehaviorSubject<string[]> {
    return this.currentRuleSetRulesRef$;
  }

  get currentRuleSetMetadata(): BehaviorSubject<StateModel.TCurrentRuleSetMetadataState> {
    return this.currentRuleSetMetadata$;
  }

  get currentAvailableRules(): BehaviorSubject<StateModel.IDocOption[]> {
    return this.currentAvailableRules$;
  }

  get ruleSetTypes(): BehaviorSubject<string[]> {
    return this.ruleSetTypes$;
  }

  get isNew(): BehaviorSubject<boolean> {
    return this.isNew$;
  }

  get isPublished(): BehaviorSubject<boolean> {
    return this.isPublished$;
  }

  get hasUnsavedChanges(): BehaviorSubject<boolean> {
    return this.hasUnsavedChanges$;
  }

  get validationResult(): BehaviorSubject<StateModel.TValidationResultState> {
    return this.validationResult$;
  }

  get validationIsStale(): BehaviorSubject<boolean> {
    return this.validationIsStale$;
  }

  get rulesLoading(): BehaviorSubject<boolean> {
    return this.rulesLoading$;
  }

  // setters

  setRuleSetOptions(options: StateModel.IRuleSetOption[]): void {
    const newState = [...options];
    this.ruleSetOptions$.next(newState);
  }

  setCurrentRuleSetId(id: string): void {
    this.currentRuleSetId$.next(id);
  }

  setCurrentRuleSetProperties(
    properties: StateModel.TCurrentRuleSetPropertiesState
  ): void {
    const newState = properties ? { ...properties } : properties;
    this.currentRuleSetProperties$.next(newState);
  }

  setCurrentRuleSetRulesRef(ids: string[]): void {
    const newState = [...ids];
    this.currentRuleSetRulesRef$.next(newState);
  }

  setCurrentRuleSetMetadata(
    metadata: StateModel.TCurrentRuleSetMetadataState
  ): void {
    const newState = metadata ? { ...metadata } : metadata;
    this.currentRuleSetMetadata$.next(newState);
  }

  setCurrentAvailableRules(rules: StateModel.IDocOption[]): void {
    const newState = [...rules];
    this.currentAvailableRules$.next(newState);
  }

  setRuleSetTypes(types: string[]): void {
    const newState = [...types];
    this.ruleSetTypes$.next(newState);
  }

  setIsNew(isNew: boolean): void {
    this.isNew$.next(isNew);
  }

  setIsPublished(isPublished: boolean): void {
    this.isPublished$.next(isPublished);
  }

  setHasUnsavedChanges(unsavedChanges: boolean): void {
    this.hasUnsavedChanges$.next(unsavedChanges);
  }

  setValidationResult(result: StateModel.TValidationResultState): void {
    const newState = result ? { ...result } : result;
    this.validationResult$.next(newState);
  }

  setValidationIsStale(isStale: boolean): void {
    this.validationIsStale$.next(isStale);
  }

  setRulesLoading(rulesLoading: boolean): void {
    this.rulesLoading$.next(rulesLoading);
  }
}
