import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { DocumentEditorFacade } from 'src/app/facades/document-editor-facade.service';

@Component({
  selector: 'rct-template-selector',
  templateUrl: './template-selector.component.html',
  styleUrls: ['./template-selector.component.scss'],
})
export class TemplateSelectorComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];
  templateOptions: any[];
  selectedTemplate: any;

  constructor(
    private documentEditorFacade: DocumentEditorFacade,
    private ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    this.initStateSubscribers();
    this.documentEditorFacade.loadTemplateOptions().subscribe();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }

  initStateSubscribers() {
    this.subs.push(
      this.documentEditorFacade
        .getTemplateOptions()
        .subscribe((options: any[]) => {
          this.templateOptions = options;
        })
    );
  }

  cancel() {
    this.ref.close('');
  }

  handleSelectTemplate(event: any) {
    this.ref.close(event.currentTarget.value);
  }
}
