import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullPageSpinnerComponent } from './full-page-spinner/full-page-spinner.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LoadingService } from './loading.service';

@NgModule({
  declarations: [FullPageSpinnerComponent],
  providers: [LoadingService],
  imports: [CommonModule, ProgressSpinnerModule],
  exports: [FullPageSpinnerComponent],
})
export class LoadingModule {}
