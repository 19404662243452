import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[rctPrimeButtonId]'
})
export class PrimeButtonIdDirective implements AfterViewInit {

  @Input() rctPrimeButtonId: string = '';

  constructor(private el: ElementRef) { }

  ngAfterViewInit(): void {
    const button = this.el.nativeElement.querySelector('button');
    button.setAttribute('id', this.rctPrimeButtonId);
  }
}
