<p-button
  #triggerButton
  rctPrimeButtonId="validation-error-btn"
  type="button"
  icon="pi pi-exclamation-circle"
  label="{{ unresolvedErrorCount }} Validation Error{{
    unresolvedErrorCount === 1 ? '' : 's'
  }}"
  styleClass="p-button-rounded p-button-danger"
  [disabled]="unresolvedErrorCount === 0"
  (click)="validationOverlay.toggle($event)"
  ><ng-template pTemplate="content">
    <span
      class="p-button-icon p-button-icon-left pi pi-exclamation-circle"
    ></span>
    <span class="p-button-label"
      ><span id="validation-error-count">{{ unresolvedErrorCount }}</span>
      <span class="collapsible-text">
        Validation Error{{ unresolvedErrorCount === 1 ? "" : "s" }}</span
      ></span
    >
  </ng-template></p-button
>

<p-overlayPanel
  #validationOverlay
  rctPrimeOverlayId="validation-error-overlay"
  [showCloseIcon]="true"
  (onShow)="handleOverlayToggle()"
  (onHide)="handleOverlayToggle()"
>
  <ng-template pTemplate>
    <div *ngIf="stale" class="warn-message p-2 flex align-items-center">
      <i class="pi pi-exclamation-triangle mr-2" style="font-size: 2rem"></i>
      <span
        >RuleSet has been changed since last validation. Re-validate to see
        current results.</span
      >
    </div>
    <p-table
      [value]="errors"
      [autoLayout]="true"
      [paginator]="errors.length > 5"
      [rows]="5"
    >
      <ng-template pTemplate="header"
        ><tr>
          <th>Error Code</th>
          <th>Description</th>
          <th>To Resolve</th>
          <th>
            <div
              class="flex flex-column align-items-center justify-content-evenly"
            >
              <span class="mb-2" style="text-align: center">Mark Resolved</span>
              <button
                pButton
                id="mark-all-resolved-btn"
                type="button"
                class="
                  p-button-sm p-button-rounded p-button-danger
                  mark-all-resolved-btn
                "
                icon="pi pi-check"
                label="All"
                (click)="markAllErrorsResolved()"
              ></button>
            </div>
          </th></tr
      ></ng-template>
      <ng-template pTemplate="body" let-error>
        <tr class="error-row-{{ error.code }}">
          <td class="code-cell {{ error.resolved ? 'light-text' : '' }}">
            <span class="column-title">Error Code:</span>
            <span class="cell-text">{{ error.code }}</span>
          </td>
          <td class="description-cell {{ error.resolved ? 'light-text' : '' }}">
            <span class="column-title">Description:</span>
            <span class="cell-text">{{ error.displayDescription }}</span>
          </td>
          <td class="solution-cell {{ error.resolved ? 'light-text' : '' }}">
            <span class="column-title">To Resolve:</span>
            <span class="cell-text">{{ error.suggestedSolution }}</span>
          </td>
          <td class="button-cell">
            <div
              class="flex flex-column align-items-center justify-content-center"
            >
              <span class="column-title">Mark Resolved</span>
              <button
                pButton
                type="button"
                class="p-button-sm p-button-rounded mark-resolved-btn {{
                  error.resolved
                    ? 'p-button-success resolved'
                    : 'p-button-danger'
                }}"
                icon="pi pi-check"
                (click)="markErrorResolved(error)"
              ></button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-template>
</p-overlayPanel>
