import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function invalidTypeValidator(validTypes: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const valid = validTypes.includes(control.value);
    return valid ? null : { invalidType: { value: control.value } };
  };
}

export function invalidYearValidator(
  minYear: number,
  maxYear: number
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const year = Number(control.value);
    const valid = year >= minYear && year <= maxYear;
    return valid ? null : { invalidYear: { value: control.value } };
  };
}

export function cannotBeEmptyValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const valid = !/^\s*$/.test(control.value); //check for empty input, including whitespace characters
    return valid ? null : { inputEmpty: control.value };
  };
}
