import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as StateModel from '../models/state.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentEditorStateService {
  // state properties

  private docTypeOptions$: BehaviorSubject<StateModel.IDocTypeOption[]> =
    new BehaviorSubject<StateModel.IDocTypeOption[]>([]);

  //stores an object containing a collection name, the number of documents in the collection, and a display name
  private docType$: BehaviorSubject<StateModel.TDocTypeState> =
    new BehaviorSubject<StateModel.TDocTypeState>(null);

  //currently stores an array of complete document objects until a smaller "document option" projection is implemented on the backend
  private docOptions$: BehaviorSubject<StateModel.IFullDoc[]> =
    new BehaviorSubject<StateModel.IFullDoc[]>([]);

  private currentDocId$: BehaviorSubject<string> = new BehaviorSubject('');

  //stores an object containing only the user-editable properties of the selected document
  private currentDocContent$: BehaviorSubject<StateModel.TCurrentDocContentState> =
    new BehaviorSubject<StateModel.TCurrentDocContentState>(null);

  //stores an object containing only the readonly properties of the selected document (for use by the server and database)
  private currentDocMetadata$: BehaviorSubject<StateModel.TCurrentDocMetadataState> =
    new BehaviorSubject<StateModel.TCurrentDocMetadataState>(null);

  private isNewDoc$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private hasUnsavedChanges$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  //same as docOptions but specifically stores "module" objects - the current collection name for templates
  private templateOptions$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    []
  );

  private templateName$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );

  // getters

  get docTypeOptions(): BehaviorSubject<StateModel.IDocTypeOption[]> {
    return this.docTypeOptions$;
  }

  get docType(): BehaviorSubject<StateModel.TDocTypeState> {
    return this.docType$;
  }

  get docOptions(): BehaviorSubject<StateModel.IFullDoc[]> {
    return this.docOptions$;
  }

  get currentDocId(): BehaviorSubject<string> {
    return this.currentDocId$;
  }

  get currentDocContent(): BehaviorSubject<StateModel.TCurrentDocContentState> {
    return this.currentDocContent$;
  }

  get currentDocMetadata(): BehaviorSubject<StateModel.TCurrentDocMetadataState> {
    return this.currentDocMetadata$;
  }

  get isNewDoc(): BehaviorSubject<boolean> {
    return this.isNewDoc$;
  }

  get hasUnsavedChanges(): BehaviorSubject<boolean> {
    return this.hasUnsavedChanges$;
  }

  get templateName(): BehaviorSubject<string> {
    return this.templateName$;
  }

  get templateOptions(): BehaviorSubject<any[]> {
    return this.templateOptions$;
  }

  // setters

  setDocTypeOptions(options: StateModel.IDocTypeOption[]): void {
    const newState = [...options];
    this.docTypeOptions$.next(newState);
  }

  setDocType(docType: StateModel.TDocTypeState): void {
    const newState = docType ? { ...docType } : docType;
    this.docType$.next(newState);
  }

  setDocOptions(docs: StateModel.IFullDoc[]): void {
    const newState = [...docs];
    this.docOptions$.next(newState);
  }

  setCurrentDocId(id: string): void {
    this.currentDocId.next(id);
  }

  setCurrentDocContent(content: StateModel.TCurrentDocContentState): void {
    const newState = content ? { ...content } : content;
    this.currentDocContent$.next(newState);
  }

  setCurrentDocMetadata(metadata: StateModel.TCurrentDocMetadataState): void {
    const newState = metadata ? { ...metadata } : metadata;
    this.currentDocMetadata$.next(newState);
  }

  setIsNewDoc(newDoc: boolean): void {
    this.isNewDoc$.next(newDoc);
  }

  setHasUnsavedChanges(hasUnsavedChanges: boolean): void {
    this.hasUnsavedChanges$.next(hasUnsavedChanges);
  }

  setTemplateOptions(options: any[]): void {
    const newState = [...options];
    this.templateOptions$.next(newState);
  }

  setTemplateName(name: string): void {
    this.templateName$.next(name);
  }
}
