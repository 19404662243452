<div id="template-selector" style="height: calc(100% - 55px)">
  <p-listbox
    [options]="templateOptions"
    ngDefaultControl
    [(ngModel)]="selectedTemplate"
    optionLabel="name"
    optionValue="_id"
    [filter]="true"
    emptyFilterMessage="No rule templates available"
    emptyMessage="No rule templates available"
    [listStyle]="{ 'max-height': 'calc(100% - 67px)' }"
    styleClass="mt-3"
  >
    <ng-template let-option pTemplate="item">
      <div
        class="flex justify-content-between align-items-center list-item"
        id="template-option-{{ option._id }}"
      >
        <p class="mr-6">{{ option.name }}</p>
        <button
          pButton
          type="button"
          label="Select"
          title="Select this template"
          class="p-button-info p-button-sm mr-3 select-item-button"
          value="{{ option._id }}"
          (click)="handleSelectTemplate($event)"
        ></button>
      </div>
    </ng-template>
  </p-listbox>
  <div class="flex justify-content-evenly align-items-center pt-2 mt-2">
    <button
      pButton
      id="cancel-btn"
      type="button"
      label="Cancel"
      title="Cancel"
      class="p-button-secondary p-button-sm main-btn"
      icon="pi pi-times"
      (click)="cancel()"
    ></button>
    <button
      pButton
      id="blank-rule-btn"
      type="button"
      label="Create Blank Rule"
      title="Create blank rule without template"
      class="p-button-success p-button-sm main-btn"
      icon="pi pi-plus"
      value="-1"
      (click)="handleSelectTemplate($event)"
    ></button>
  </div>
</div>
