import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[rctPrimeDropdownButtonLabel]',
})
export class PrimeDropdownButtonLabelDirective implements AfterViewInit {
  @Input() rctPrimeDropdownButtonLabel: string = '';

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    const button = this.el.nativeElement.querySelector('button');
    button.setAttribute('aria-label', this.rctPrimeDropdownButtonLabel);
  }
}
