import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { RuleSetEditorFacadeService } from 'src/app/facades/ruleset-editor-facade.service';

@Component({
  selector: 'rct-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss'],
})
export class AuditLogComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];

  saved: boolean = true;
  validated: 'yes' | 'missingChanges' | 'no' = 'no';
  published: 'upToDate' | 'missingChanges' | 'never' = 'never';
  createdAt: string = '';
  lastUpdatedAt: string = '';
  originalAuthor: any = {};
  lastUpdateAuthor: any = {};

  constructor(private facade: RuleSetEditorFacadeService) {}

  ngOnInit(): void {
    this.initStateSubscribers();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  initStateSubscribers(): void {
    this.subs.push(
      this.facade.getHasUnsavedChanges().subscribe((result) => {
        this.saved = !result;
      })
    );

    this.subs.push(
      combineLatest([
        this.facade.getValidationStatus(),
        this.facade.getValidationIsStale(),
      ]).subscribe(([status, isStale]) => {
        if (status === 'success') {
          if (isStale) this.validated = 'missingChanges';
          else this.validated = 'yes';
        } else this.validated = 'no';
      })
    );

    // TO-DO: published status will have to come from the ruleset itself via the api

    this.subs.push(
      this.facade.getCurrentRuleSetMetadata().subscribe((result) => {
        if (result) {
          this.createdAt = new Date(result.created_when).toLocaleString();
          this.lastUpdatedAt = new Date(result.updated_when).toLocaleString();
          this.originalAuthor = {
            key: result.primary_user_key,
            facility: result.primary_facility_key,
          };
          const lastUpdate =
            result.tracking_info[result.tracking_info.length - 1];
          this.lastUpdateAuthor = {
            key: lastUpdate.user.key,
            facility: lastUpdate.facility.key,
          };
        } else {
          this.createdAt = '';
          this.lastUpdatedAt = '';
          this.originalAuthor = {};
          this.lastUpdateAuthor = {};
        }
      })
    );
  }
}
