import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TValidCollectionName } from '../models/api.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private BASE_URL: string = '/api';

  constructor(private http: HttpClient) {}

  getCollectionList(): Observable<any> {
    return this.http.get(`${this.BASE_URL}/db_status`);
  }

  getDocumentList(collection: TValidCollectionName): Observable<any> {
    return this.http.get(`${this.BASE_URL}/${collection}`);
  }

  getDocument(collection: TValidCollectionName, id: string): Observable<any> {
    return this.http.get(`${this.BASE_URL}/${collection}?doc_id=${id}`);
  }

  createDocument(collection: TValidCollectionName, doc: any): Observable<any> {
    const json = JSON.stringify(doc);
    return this.http.post(
      `${this.BASE_URL}/${collection}?document=${json}`,
      null
    );
  }

  updateDocument(collection: TValidCollectionName, formData: FormData): Observable<any> {
    const doc = JSON.parse(formData.get('document') as string);
    if (!doc.hasOwnProperty('_id')) {
      return throwError(
        new Error(
          'To update an existing document you must provide an "_id" property'
        )
      );
    }
    return this.http.post(`${this.BASE_URL}/${collection}`, formData);
  }

  deleteDocument(collection: TValidCollectionName, docId: string): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/${collection}?doc_id=${docId}`);
  }

  validateRuleSet(ruleSetId: string): Observable<any> {
    return this.http.get(`${this.BASE_URL}/validate_rule_set?doc_id=${ruleSetId}`);
  }
}
