import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ValidationErrorMessageService } from 'src/app/core/validation-error-message/validation-error-message.service';
import { ValidationOverlayTriggerService } from 'src/app/core/validation-overlay-trigger/validation-overlay-trigger.service';
import { RuleSetEditorFacadeService } from 'src/app/facades/ruleset-editor-facade.service';
import * as StateModel from '../../core/models/state.model';

interface IDisplayValidationError extends StateModel.IValidationError {
  resolved: boolean;
  displayDescription: string;
  suggestedSolution: string;
}

@Component({
  selector: 'rct-validation-error-display',
  templateUrl: './validation-error-display.component.html',
  styleUrls: ['./validation-error-display.component.scss'],
})
export class ValidationErrorDisplayComponent
  implements OnInit, OnDestroy
{
  subs: Subscription[] = [];

  @ViewChild('validationOverlay') validationOverlay: any;
  @ViewChild('triggerButton') triggerButton: any;

  @Input() stale: boolean = false;

  metrics: StateModel.IValidationMetrics = {};

  private _errors: IDisplayValidationError[] = [];
  unresolvedErrorCount: number = 0;

  constructor(
    private facade: RuleSetEditorFacadeService,
    private errorMessageService: ValidationErrorMessageService,
    private overlayTrigger: ValidationOverlayTriggerService,
    private messageService: MessageService
  ) {}

  get errors(): IDisplayValidationError[] {
    return this._errors;
  }

  set errors(newErrs: IDisplayValidationError[]) {
    this._errors = newErrs;
    this._sortErrors();
    this._updateUnresolvedErrorCount();
  }

  ngOnInit(): void {
    this.initStateSubscribers();
    this.initOverlayEventSubscribers();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  initStateSubscribers(): void {
    this.subs.push(
      this.facade
        .getValidationResult()
        .subscribe((result: StateModel.TValidationResultState) => {
          this.metrics = result?.metrics ? result.metrics : {};
          this.errors = result?.errors
            ? this._addDisplayProperties(result.errors)
            : [];
        })
    );
  }

  initOverlayEventSubscribers(): void {
    this.subs.push(
      this.overlayTrigger.showOverlay.subscribe(() => {
        this.validationOverlay.show({
          target: this.triggerButton.nativeElement,
        });
      })
    );
    this.subs.push(
      this.overlayTrigger.hideOverlay.subscribe(() => {
        this.validationOverlay.hide();
      })
    );
  }

  markErrorResolved(error: IDisplayValidationError): void {
    error.resolved = true;
    this._updateUnresolvedErrorCount();
    if (this.unresolvedErrorCount < 1) this.validationOverlay.hide();
    else this._sortErrors();
  }

  markAllErrorsResolved(): void {
    this.errors.forEach(
      (err: IDisplayValidationError) => (err.resolved = true)
    );
    this.unresolvedErrorCount = 0;
    this.validationOverlay.hide();
  }

  handleOverlayToggle(): void {
    this.messageService.clear();
  }

  private _updateUnresolvedErrorCount(): void {
    this.unresolvedErrorCount = this._errors.filter(
      (err: IDisplayValidationError) => !err.resolved
    ).length;
  }

  private _addDisplayProperties(
    errors: StateModel.IValidationError[]
  ): IDisplayValidationError[] {
    return errors.map((err: StateModel.IValidationError) => {
      const displayErr = { ...err } as IDisplayValidationError;
      const { description, solution } =
        this.errorMessageService.generateErrorMessage(err);
      displayErr.resolved = false;
      displayErr.displayDescription = description;
      displayErr.suggestedSolution = solution;
      return displayErr;
    });
  }

  private _sortErrors(): void {
    this._errors.sort(
      (errA: IDisplayValidationError, errB: IDisplayValidationError) => {
        //first sort by resolved status
        return errA.resolved && !errB.resolved
          ? 1
          : !errA.resolved && errB.resolved
            ? -1
            : 0 ||
            //second sort by error level ascending
            Number(errA.level) - Number(errB.level) ||
            //third sort by last error code digit ascending
            Number(errA.code[5]) - Number(errB.code[5]) ||
            //fourth sort by minor error code type (middle error code digit) ascending
            Number(errA.code[3]) - Number(errB.code[3]) ||
            //final sort by major error code type (first error code digit) ascending
            Number(errA.code[0]) - Number(errB.code[0]);
      }
    );
  }
}
