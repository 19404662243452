import { Component, Input, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'rct-json-viewer',
  templateUrl: './json-viewer.component.html',
  styleUrls: ['./json-viewer.component.scss'],
})
export class JsonViewerComponent implements OnInit {
  modalInput: any;
  @Input() json: any;

  constructor(private config: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.config?.data?.json) this.modalInput = this.config.data.json;
  }
}
