import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PrimeDropdownButtonLabelDirective } from './directives/prime-dropdown-button-label.directive';
import { PrimeDropdownButtonIdDirective } from './directives/prime-dropdown-button-id.directive';
import { HideCollapsedJsonContentDirective } from './directives/hide-collapsed-json-content.directive';
import { PrimeDropdownIdDirective } from './directives/prime-dropdown-id.directive';
import { PrimePicklistIdsDirective } from './directives/prime-picklist-ids.directive';
import { PrimeButtonIdDirective } from './directives/prime-button-id.directive';
import { PrimeOverlayIdDirective } from './directives/prime-overlay-id.directive';
import { AddSplitButtonClassesDirective } from './directives/add-split-button-classes.directive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PrimeSplitButtonIdsDirective } from './directives/prime-split-button-ids.directive';

@NgModule({
  declarations: [
    PrimeDropdownButtonLabelDirective,
    PrimeDropdownButtonIdDirective,
    HideCollapsedJsonContentDirective,
    PrimeDropdownIdDirective,
    PrimePicklistIdsDirective,
    PrimeButtonIdDirective,
    PrimeOverlayIdDirective,
    AddSplitButtonClassesDirective,
    PrimeSplitButtonIdsDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PrimeDropdownButtonLabelDirective,
    PrimeDropdownButtonIdDirective,
    HideCollapsedJsonContentDirective,
    PrimeDropdownIdDirective,
    PrimePicklistIdsDirective,
    PrimeButtonIdDirective,
    PrimeOverlayIdDirective,
    AddSplitButtonClassesDirective,
    PrimeSplitButtonIdsDirective,
  ],
})
export class SharedModule {}
