<p-progressSpinner
  *ngIf="loading"
  styleClass="large-progress-spinner"
  [style]="{
    height: '20vw',
    width: '20vw',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-10vw, -10vw)'
  }"
></p-progressSpinner>
