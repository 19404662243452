import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ValidationOverlayTriggerService {
  showOverlay: Subject<any> = new Subject();
  hideOverlay: Subject<any> = new Subject();

  constructor() {}

  openOverlay(): void {
    this.showOverlay.next('');
  }

  closeOverlay(): void {
    this.hideOverlay.next('');
  }
}
