import { BehaviorSubject } from 'rxjs';

export class LoadingService {
  private isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  get isLoading(): BehaviorSubject<boolean> {
    return this.isLoading$;
  }

  startLoading(): void {
    this.isLoading$.next(true);
  }

  stopLoading(): void {
    this.isLoading$.next(false);
  }
}
