import { Injectable } from '@angular/core';
import * as StateModel from '../models/state.model';
import { DocumentEditorStateService } from '../state/document-editor-state.service';
import { RuleSetEditorStateService } from '../state/ruleset-editor-state.service';

@Injectable({
  providedIn: 'root',
})
export class DocParserService {
  docMetadataFields: string[] = [
    'primary_facility_key',
    'primary_user_key',
    'created_when',
    'updated_when',
    '_version',
    'tracking_info',
  ];
  ruleSetMetadataFields: string[] = [];

  constructor(
    private docState: DocumentEditorStateService,
    private ruleSetState: RuleSetEditorStateService
  ) {}

  compileFullDoc = (docParts?: {
    _id?: string;
    content?: StateModel.IDocContent | null;
    metadata?: StateModel.IDocMetadata | null;
  }): StateModel.IFullDoc | null => {
    const _id = docParts?._id
      ? docParts._id
      : this.docState.currentDocId.getValue();

    if (!_id) return null;

    const content = docParts?.content
      ? docParts.content
      : this.docState.currentDocContent.getValue();
    const metadata = docParts?.metadata
      ? docParts.metadata
      : this.docState.currentDocMetadata.getValue();

    return { _id, ...content, ...metadata } as StateModel.IFullDoc;
  };

  parseFullDoc = (
    doc: StateModel.IFullDoc | null
  ): StateModel.IParsedDoc | null => {
    if (!doc) return null;
    const { _id, name, ...rest } = doc;
    const content = { name } as StateModel.IDocContent;
    const metadata = {} as StateModel.IDocMetadata;

    for (const prop in rest) {
      if (this.docMetadataFields.includes(prop)) metadata[prop] = rest[prop];
      else content[prop] = rest[prop];
    }
    return { _id, content, metadata };
  };

  compileFullRuleSet = (ruleSetParts?: {
    _id?: string;
    properties?: StateModel.TCurrentRuleSetPropertiesState;
    rulesRef?: string[];
    metadata?: StateModel.TCurrentRuleSetMetadataState;
  }): StateModel.IFullRuleSet | null => {
    const _id = ruleSetParts?._id
      ? ruleSetParts._id
      : this.ruleSetState.currentRuleSetId.getValue();

    if (!_id) return null;

    const properties = ruleSetParts?.properties
      ? ruleSetParts.properties
      : this.ruleSetState.currentRuleSetProperties.getValue();

    const rules_ref = ruleSetParts?.rulesRef
      ? ruleSetParts.rulesRef
      : this.ruleSetState.currentRuleSetRulesRef.getValue();

    const metadata = ruleSetParts?.metadata
      ? ruleSetParts.metadata
      : this.ruleSetState.currentRuleSetMetadata.getValue();

    return {
      _id,
      rules_ref,
      ...properties,
      ...metadata,
    } as StateModel.IFullRuleSet;
  };

  parseFullRuleSet = (
    ruleSet: StateModel.IFullRuleSet | null
  ): StateModel.IParsedRuleSet | null => {
    if (!ruleSet) return null;
    const { _id, name, year, type, description, ...partialRuleSet } = ruleSet;

    const properties = {
      name,
      year,
      type,
      description,
    } as StateModel.IRuleSetProperties;

    const { rulesRef, rest } = this._extractRulesRef(partialRuleSet);

    const metadata = rest as StateModel.IRuleSetMetadata;

    return { _id, properties, rulesRef, metadata };
  };

  /**
   * Separate either rules or rules_ref array from other properties 
   * @param partialRuleSet a RuleSet object minus its main properties (name, type, year, description)
   * @returns an object with a rulesRef property and a "rest" property containing all other data from passed-in RuleSet
   */
  private _extractRulesRef(partialRuleSet: any): {
    rulesRef: string[];
    rest: any;
  } {
    let rulesRef = [];
    let rest;

    if (partialRuleSet.rules_ref) {
      const { rules_ref, ...other } = partialRuleSet;
      rulesRef = rules_ref;
      rest = other;
    } else if (partialRuleSet.rules) {
      const { rules, ...other } = partialRuleSet;
      rulesRef = rules;
      rest = other;
    } else {
      rest = partialRuleSet;
    }

    return { rulesRef, rest };
  }
}
