import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[rctAddSplitButtonClasses]',
})
export class AddSplitButtonClassesDirective implements AfterViewInit {
  @Input() defaultButtonStyleClass: string = '';
  @Input() menuButtonStyleClass: string = '';

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    const defaultBtn = this.el.nativeElement.querySelector(
      'button.p-splitbutton-defaultbutton'
    );
    const menuBtn = this.el.nativeElement.querySelector(
      'button.p-splitbutton-menubutton'
    );

    defaultBtn.classList.add(...this.defaultButtonStyleClass.split(' '));
    menuBtn.classList.add(...this.menuButtonStyleClass.split(' '));
  }
}
